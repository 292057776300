import { SearchData } from './utils';

const ASPECT_DESC = {
    'acs1': '1-Year Estimates',
    'acs5': '5-Year Averages',
}

export type TimeSelectorProps = {
    result_index: number,
    data: SearchData,
    update: (new_data: any) => void

}

export function TimeSelector(props: TimeSelectorProps) {
    let indicator = props.data['results'][props.result_index];
    if (!indicator.data_product || !indicator.vintage || !indicator.meta) {
        return <div style={{display: 'none'}}></div>;
    }

    let vintage_avails = indicator.meta.vintage_avails;
    let data_product_options = Object.keys(vintage_avails).sort();
    let active_data_product = indicator.data_product;
    if (active_data_product == 'decennial_census') {
        return <div></div>;
    }
    let vintage_options = vintage_avails[active_data_product];

    if (!vintage_options) {
        throw new Error('Cannot render time selector: No vintage options for active data product: ' + active_data_product);
    }

    let active_vintage = indicator.vintage;
    
    return (
        <div>
            <div style={{marginTop:'10px'}} >
                <label>
                    <strong>Census Estimate Type: </strong>
                    <select 
                        value={active_data_product}
                        onChange={(ev) => {
                            let selected_data_product = ev.target.value;
                            let new_vintage = active_vintage;
                            if (!vintage_avails[selected_data_product].includes(new_vintage)) {
                                let all_vintages = vintage_avails[selected_data_product].sort();
                                new_vintage = all_vintages[all_vintages.length - 1]; // use latest
                            }
                            let new_results = [...props.data.results]
                            new_results[props.result_index].data_product = selected_data_product;
                            new_results[props.result_index].vintage = new_vintage;
                            props.update({results: new_results, only_index: props.result_index});
                        }}
                    >
                        {data_product_options.map((option: string) => (
                            <option key={option} value={option}>
                                {ASPECT_DESC[option]}
                                
                            </option>
                        ))}
                    </select>
                </label>
            </div>

            <div>
                <label>
                    <strong>Vintage: </strong>
                    <select 
                        value={active_vintage}
                        onChange={(ev) => {
                            let selected_vintage = ev.target.value;
                            let new_results = [...props.data.results]
                            new_results[props.result_index].vintage = selected_vintage;
                            props.update({results: new_results, only_index: props.result_index});
                        }}
                    >
                        {vintage_options.map((option: string) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
        </div>
    );
}