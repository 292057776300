import { MapStyle } from './map_style';
import { Map } from '../map';
import { Layer } from './layer';
import { LayerIdMap, Level } from '../types';
import * as Leaflet from 'leaflet';
import { constants } from '../helpers/constants';
import { findLevel } from '../helpers/levels';
import * as ZIP_COUNTIES from '../helpers/zip_counties.json';

// Neighbors of current scope. 
// Clicking a neighbor adds it to scope, and removes from neighbors
export class NeighborsLayer extends Layer {
    map: Map;
    layer_ids: LayerIdMap;
    targets: string[];
    updating: boolean = false;

    constructor(map: Map) {
        super();
        this.map = map;
    }

    async update(targets: string[]) {
        // block clicks while updating
        this.updating = true;

        this.targets = targets;
        
        this.remove();

        if (targets.includes(constants.CONUS_ID)) {
            return;
        }

        await this.create();
        this.add();
        this.updating = false;
    }

    async create() {
        let geojson = await this.getFeatures();

        this.layer_ids = {};

        this.leaflet_layer = new Leaflet.GeoJSON(geojson, {
            onEachFeature: (feature, layer: Leaflet.GeoJSON) => {
                if (this.targets.includes(feature.properties.id)) {
                    this.map.leaflet_map.removeLayer(layer);
                    layer.setStyle(MapStyle.getInvisibleStyle());
                }
                else {
                    layer.on({click: (ev) => this.handleNeighborClick(ev)})
                    layer.on({mouseover: (ev) => this.highlightNeighbor(ev)})
                    layer.on({mouseout: (ev) => this.unHighlightNeighbor(ev)})
                    layer.setStyle(MapStyle.getNeighborStyle());
                }
                this.layer_ids[feature.properties.id] = layer;
            },
            pane: 'neighbors'
        });
    }

    async getFeatures() {
        let level = findLevel(this.map.scope.place_ids[0]);

        // TODO: Assume counties have adj (neighbors)
        // for tract, blockgroup and zipcode, we want to load neighbors of active + adj counties
        if ([Level.Tract, Level.BlockGroup].includes(level)) {
            let all_counties = new Set (this.targets.map((id) => id.slice(0, 5)));
            let counties_in_scope = Array.from(new Set(all_counties));
            for (var i = 0; i < counties_in_scope.length; i++) {
                let info = await this.map.places.getPlaceInfo(counties_in_scope[i]);
                if (!info.adj) {
                    throw {'error': 'No adj key found in county geojson: ' + counties_in_scope[i]};
                }
                info.adj.forEach((id) => all_counties.add(id));
            }
            let counties = Array.from(new Set(all_counties));
            return await this.map.places.getShapes(counties, level);
        }
        else if (level == Level.ZipCode) {
            let zip_codes = this.targets.map(id => id.split('_')[1]);
            let counties_in_scope = zip_codes.map(zip => ZIP_COUNTIES[zip]);
            counties_in_scope = counties_in_scope.reduce((acc, val) => acc.concat(val), []);
            let county_set = new Set(counties_in_scope);
            counties_in_scope = Array.from(county_set);
            for (var i = 0; i < counties_in_scope.length; i++) {
                let info = await this.map.places.getPlaceInfo(counties_in_scope[i]);
                if (!info.adj) {
                    throw {'error': 'No adj key found in county geojson: ' + counties_in_scope[i]};
                }
                info.adj.forEach((id) => county_set.add(id));
            }
            let counties = Array.from(county_set);
            let states = counties.map(county => county.slice(0, 2));
            states = Array.from(new Set(states));
            return await this.map.places.getShapes(states, Level.ZipCode);
        }
       
        return await this.map.places.getShapes([constants.CONUS_ID], level);
    }

    async handleNeighborClick(ev: Leaflet.LeafletMouseEvent) {
        if (this.updating) {
            return;
        }
        this.updating = true;

        // remove from layer first 
        let target_id = ev.target.feature.properties.id;
        this.map.leaflet_map.removeLayer(this.layer_ids[target_id]);
        let new_scope = this.map.scope.place_ids.concat([target_id]);
        let success = await this.map.scope.update(new_scope);
        if (success) {
            this.map.update(false);
            this.update(new_scope);
        }
        this.updating = false;
    }

    highlightNeighbor(ev: Leaflet.LeafletMouseEvent) {
        ev.target.setStyle(MapStyle.getNeighborHighlightStyle());
    }

    unHighlightNeighbor(ev: Leaflet.LeafletMouseEvent) {
        ev.target.setStyle(MapStyle.getNeighborStyle());
    }

    removeFeature(place_id: string) {
        this.map.leaflet_map.removeLayer(this.layer_ids[place_id]);
    }
}