import { Map } from '../../map';
import { findAncestors, findLevel, plural } from '../../helpers/levels';
import { Level } from '../../types';
import * as Leaflet from 'leaflet';

// This shows all available zoom levels, based on current scope 
// E.g. Showing Tracts in Arlington, user can zoom out to VA or US, or toggle to block groups.
export class Hierarchy {
    map: Map;
    parent_div: HTMLElement;

    constructor(map: Map, parent: HTMLElement) {
        this.map = map;
        this.parent_div = parent;
        this.update();
    }


    update() {
        let ancestors = findAncestors(this.map.scope.place_ids);

        let container = Leaflet.DomUtil.create('h3', '', this.parent_div);
        if (this.map.data_hidden) {
            container.innerText = 'Loading ...';
            return;
        }


        // zoom buttons for ancestors, e.g. US > Virginia/Maryland 
        ancestors.forEach( (ids, index) => {
            if (index != 0) {
                let arrow = Leaflet.DomUtil.create('span', '', container);
                arrow.innerText = ' > '
            }
            let label = this.createLabel(ids);
            let linkbutton = Leaflet.DomUtil.create('button', 'linkbutton', container)
            linkbutton.innerText = label;
            linkbutton.onclick = (ev) => this.handleZoomClick(ev, ids);
        });

        // current scope - not clickable
        let scope_label = this.createLabel(this.map.scope.place_ids);
        let prefix = ancestors.length > 0 ? ' > ' : '';

        let scope_span = Leaflet.DomUtil.create('span', '', container);
        scope_span.innerText = `${prefix}${scope_label}`;
    }

    createLabel(ids: string[]) {
        if (ids.length == 1) {
            let info = this.map.places.getPlaceInfo(ids[0]);
            return info.name;
        }
        else if (ids.length > 5) {
            let place_label = plural(findLevel(ids[0]));
            return `${ids.length} ${place_label}`;
        }
        else {
            let names = ids.map(id => this.map.places.getPlaceInfo(id).name);
            return names.join(',');
        }
    }
    
    async handleZoomClick(ev: MouseEvent, ids: string[]) {
        ev.stopPropagation();

        let level = findLevel(ids[0]);
        if (level == Level.Block) {
            return;
        }
        if ([Level.ZipCode, Level.Tract, Level.BlockGroup].includes(level) && !['P20', 'H20', 'O20', 'V20'].includes(this.map.data_set.key)) {
            let prev_key = this.map.data_set.key;
            let prev_name = this.map.data_set.name;
            let available = ['P20', 'H20', 'O20', 'V20'];
            // if any of avail is substr of prev_key, do nothing
            if (!available.some( (avail) => prev_key.includes(avail))) {
                let result = confirm(`This indicator (${prev_name}) is unavailable for blocks - switch to Total Population (2020 Decennial Census)?`)
                if (!result) {
                    return;
                }
                await this.map.forceTotalPopulation();
                let new_data_set = { 
                    key: 'P20~LAND_AREA',
                    name: 'Total Population (2020 Decennial Census)',
                    uom: 'people',
                    vintage: '2020', 
                    data_product: 'decennial_census'
                }
                this.map.data_set = new_data_set;
                await this.map.update(false);
            }
        }
        let success = await this.map.zoomTo(ids);
        if (!success) {
            console.error('failed to zoom to ' + ids);
        }
    }
}