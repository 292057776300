import * as Leaflet from "leaflet";
import { Map } from '../map';

export class AddressMarker {
    leaflet_marker: Leaflet.Marker;
    map: Map;

    constructor(map: Map) {
        this.ensureIconAvailable()
        this.map = map;
    }

    // target is 
    update(target: [number, number]) {
        if (this.leaflet_marker) {
            this.remove();
        }
        var icon = new Leaflet.Icon.Default();
        icon.options.shadowSize = [0,0];
        let opts = {
            pane: 'address_marker',
            icon: icon
        };
        this.leaflet_marker = new Leaflet.Marker(target, opts);
        this.map.leaflet_map.addLayer(this.leaflet_marker);
    }

    remove() {
        if (this.leaflet_marker) {
            this.map.leaflet_map.removeLayer(this.leaflet_marker);
            this.leaflet_marker = null;
        }
    }

    ensureIconAvailable() {
        // this is ugly, but necessary to avoid trying to fetch a missing icon 
        // potentially a broken interaction between leaflet and parcel/webpack?
        let L = <any> Leaflet;
        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        });
    }
}