import {shapes, data} from './censusapi';
import { Level, LatLon } from '../types';

const CACHE_URL = process.env.CACHE_URL + '?';
const FORCE_REFRESH = process.env.FORCE_REFRESH || ''; 

export class API {
    static async fetchChildData(place_id: string, level: Level, variable_id: string, vintage: string, data_product: string) {
        return await data(place_id, variable_id, level, vintage, data_product);
    }

    static async fetchChildShapes(place_id: string, level: Level) {
        let key = `shapes/${place_id}/${level}`;
        if (!FORCE_REFRESH.includes('s')) {
            let cached = await API.fetchCache(key);
            if (cached) {
                return cached;
            }
        }
        let result = await shapes(place_id, level);
        API.putCache(key, result);
        return result;
    }

    static async fetchCache(key: string) {
        let LOCAL_CACHE = (<any> window).LOCAL_CACHE;
        if (!LOCAL_CACHE) {
            LOCAL_CACHE = (<any> window).LOCAL_CACHE = {};
        }
        // TODO this breaks multi-map. Why does any map read data from the shapes cache?
        if (LOCAL_CACHE[key]) {
            let value = LOCAL_CACHE[key];
            return JSON.parse(JSON.stringify(value));
        }
        let to_fetch = CACHE_URL + new URLSearchParams({key: key});
        let response;
        try {
            response = await(fetch(to_fetch));
            if (response.status == 200) {
                let result =  await response.json();
                LOCAL_CACHE[key] = result;
                return result;
            }
            else {
                return null;
            }
        }
        catch(err) {
            console.warn('Error connecting to cache server. Is it running?')
            return null;
        }
    }

    static async putCache(key: string, data: any) {
        let token = process.env.CACHE_TOKEN;
        if (token) {
            let url = CACHE_URL + new URLSearchParams({key: key});
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer: ' + token
            };
            let response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: headers
            });
            console.info(JSON.stringify(await response.json()));
        }
    }

    static async geocode(address: string): Promise<LatLon> {
        address= address.trim() + ", United States"; // osm is global
        let url = 'https://nominatim.openstreetmap.org/search?format=jsonv2&q=' + encodeURIComponent(address);
        let response = <any> await fetch(url).then(r=>r.json());
        if (response && response[0]) {
            return {
                lat: response[0]['lat'],
                lon: response[0]['lon']
            };
        }
        else {
            return null;
        }
    }
}