import {constants} from './constants';
import * as Leaflet from 'leaflet';
import setUpSync from './lsync'
import { Map } from '../map';

export class LeafletMap {
    static buildMap(map_div: string, map: Map) : Leaflet.Map {
        setUpSync(Leaflet);
        let tiles_layer = Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '© OpenStreetMap',
            pane: 'tiles',
        });

        let center = new Leaflet.LatLng(constants.US_CENTER[0], constants.US_CENTER[1]);
        let opts = {doubleClickZoom: false}
        let leaflet_map = Leaflet.map(map_div, opts).setView(center, constants.BASE_ZOOM);
        leaflet_map.zoomControl.setPosition('topright');

        leaflet_map.createPane('tiles');
        leaflet_map.getPane('tiles').style.zIndex = '1';
        leaflet_map.createPane('neighbors');
        leaflet_map.getPane('neighbors').style.zIndex = '2';
        leaflet_map.createPane('base');
        leaflet_map.getPane('base').style.zIndex = '3';
        leaflet_map.createPane('address_marker');
        leaflet_map.getPane('address_marker').style.zIndex = '4';
        leaflet_map.createPane('highlight');
        leaflet_map.getPane('highlight').style.zIndex = '5';

        tiles_layer.addTo(leaflet_map);

        return leaflet_map;
    }
}
