import { styles } from './styles';
import { SearchData, fetch_json, API_URL } from './utils';
import { useEffect, useState } from 'react';

type AlternativeMapSelectorProps = {
    result_index: number;
    data: SearchData;
    update: (new_data: any) => void;
};

export function AlternativeMapSelector(props: AlternativeMapSelectorProps) {
    let i = props.result_index
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.data.requested_indicators[i]?.var_q && (props.data.results[i]?.confirmed || props.data.results[i]?.failed) && !props.data.results[i]?.alternative_maps && !loading) {
            setLoading(true);
            findAlternativeMaps(props.result_index, props.data, props.update).then(() => setLoading(false));
        }
    }, [props.data]);


    if (!props.data.results[i]?.alternative_maps || loading) {
        return <div style={{display: 'none'}}></div>;
    }

    let options = props.data.results[i].alternative_maps.filter( alt_map => alt_map.key !== props.data.results[i].expression);
    let style = styles.altMaps;
    if (props.data.requested_indicators.length > 1) {
        style = styles.altMapsSmall;
    }

    return (
        <div style={style}>
            <div>
                <span>
                    { props.data.results[i].confirmed && <strong>See Also</strong> }
                    { !props.data.results[i].confirmed && <strong>Results</strong> }
                </span>
                <ul style={styles.variableList}>
                    {options.map( (alternate_map_info,option_index)=>
                        <li key={option_index}>
                            <InfoRow 
                                desc={alternate_map_info.display_name}
                                click_action={() => {
                                    let new_indicator = {
                                        var_q: alternate_map_info.name,
                                        vintage: props.data.requested_indicators[props.result_index].vintage,
                                        show_as: props.data.requested_indicators[props.result_index].show_as
                                    }
                                    let new_requested_indicators = []
                                    new_requested_indicators[props.result_index] = new_indicator;

                                    let new_results = [];
                                    new_results[props.result_index] = null;

                                    let update_data = {
                                        only_index: props.result_index,
                                        requested_indicators: new_requested_indicators,
                                        results: new_results
                                    }
                                    props.update(update_data);
                                } }
                            />
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
}

async function findAlternativeMaps(result_index: number, search_data: SearchData, update: (new_data: any) => void) {
    const DECENNIAL_VARS = [
        {
            "name": "Total Population (2020 Decennial Census)",
            "display_name": "Total Population (2020 Decennial Census)",
            "key": "P20",
        },
        {
            "name": "Housing Units (2020 Decennial Census)",
            "display_name": "Housing Units (2020 Decennial Census)",
            "key": "H20",
        },
        {
            "name": "Occupied Housing Units (2020 Decennial Census)",
            "display_name": "Occupied Housing Units (2020 Decennial Census)",
            "key": "O20",
        },
        {
            "name": "Vacant Housing Units (2020 Decennial Census)",
            "display_name": "Vacant Housing Units (2020 Decennial Census)",
            "key": "V20",
        }
    ]
    let exp = search_data.results[result_index]?.expression;
    let nearest_maps = await fetch_json(API_URL + 'nearest_maps?limit=20&var_query=' + encodeURIComponent(search_data.requested_indicators[result_index].var_q),
        (error_resp) => { throw Error('Unable to query for alternative maps.') } 
    );

    // inject decennial census vars as top 3 (skip selected one) and purge duplicates
    if (search_data.spatial_resolution === 'block') {
        nearest_maps = DECENNIAL_VARS.concat(nearest_maps);
    }
    else {
        nearest_maps = nearest_maps.concat(DECENNIAL_VARS);
    }
    let seen = new Set();
    if (exp) {
        seen.add(exp);
    }
    nearest_maps = nearest_maps.filter( (var_info) => {
        if (seen.has(var_info.key)) {
            return false;
        }
        seen.add(var_info.key);
        return true;
    });

    let new_result = {...search_data.results[result_index]};
    new_result.alternative_maps = nearest_maps;
    let new_results = [];
    new_results[result_index] = new_result;
    update({results: new_results, only_index: result_index});
}


type InfoRowProps = {
    click_action: () => void;
    desc: string;
};

export function InfoRow(props: InfoRowProps) {
    return (
        <div>
            <span>
                {
                    <button type={'button'} style={styles.linkButton} onClick={() => props.click_action()}>{props.desc}</button>
                }
            </span>
        </div>
    )
}
