import * as Leaflet from 'leaflet';

export const constants = {
    US_CENTER: [39.8283, -98.5795],
    BASE_ZOOM: 4.2,
    // arbitrary 'special' ID for the lower 48 + DC
    CONUS_ID: '0',
    CONUS_PROPERTIES: {
        id: '0',
        name: 'United States'
    },
    CUSTOM_POPUP_LOCATIONS: {
        // alaska
        '02': { lat: 71, lng: -152.5 },
        // Aleutians West 
        '02016': { lat: 52.5, lng: -174.5 }
    },
    CUSTOM_BOUNDS: {
        // US - zoom to continental US
        '0': new Leaflet.LatLngBounds([24.5, -125], [50, -66.5]),
        // alaska
        '02': new Leaflet.LatLngBounds([51.5, -179.9], [71, -129.5]),
        // Aleutians West 
        '02016': new Leaflet.LatLngBounds([51.17, -179.9], [57.3, -166])
    }
}