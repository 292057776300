import { API } from '../helpers/api';
import { findLevel, within } from '../helpers/levels';
import { PlaceDict, Level, LatLon } from '../types';
import { Map } from '../map';
import { FeatureCollection } from 'geojson';
import { constants } from '../helpers/constants';
import * as Leaflet from 'leaflet';

const skip = []; // ['60','66','69','72','78','02','15']; // the non-state territories plus AK and HI

// The interface for the rest of the application to request any geographic info
// Handles merging GeoJSON boundaries with data values, which are cached separately
export class PlaceRepository {
    map: Map;
    places: PlaceDict;

    constructor(map: Map) {
        this.map = map;
        this.places = {};
        (<any> window).LOCAL_CACHE ||= {};
    }
    

    async getShapes(place_ids: string[], level: Level) {
        let results = await Promise.all(place_ids.map(id => this.getChildren(id, level)));
        return this.joinShapes(place_ids, results, null);
    }

    async getShapesWithData(place_ids: string[], level: Level) {
        // TODO need to expose data product family to the map and do this properly
        if (this.map.data_set.key.includes('P20') || this.map.data_set.key.includes('H20') || this.map.data_set.key.includes('O20') || this.map.data_set.key.includes('V20')) {
            let shape_promise = Promise.all(place_ids.map(id => this.getChildren(id, level)));
            let shapes = await shape_promise;
            return this.joinShapes(place_ids, shapes, null, true);
        }
        let shape_promise = Promise.all(place_ids.map(id => this.getChildren(id, level)));
        let data_promise = Promise.all(place_ids.map(id => this.getChildData(id, level)));

        let [shapes, data] = await Promise.all([shape_promise, data_promise]);

        return this.joinShapes(place_ids, shapes, data);
    }

    joinShapes(place_ids: string[], shapes: any[], data?: any[], decennial?: boolean) {
        data ||= [];
        let result = <FeatureCollection> {
            type: 'FeatureCollection',
            features: []
        }
        
        let seen_ids = new Set();
        let normalize_by_land = this.map.data_set.key.includes('~LAND_AREA');

        for (let i = 0; i < shapes.length; i++) {
            // null values is okay - still show layer if there are features
            let data_for_shape = data[i] || {
                combined: {},
                normalize: {},
                raw: {}
            };
            let values = data_for_shape.combined || {};
            let norm_values = data_for_shape.normalize || {};
            let raw_values = data_for_shape.raw || {};

            if (!shapes[i].features) {
                continue;
            }

            let parent_id = place_ids[i];

            this.places[parent_id] ||= {};
            this.places[parent_id].child_ids ||= new Set();

            shapes[i].features.forEach(feature => {
                let id = feature.properties.GEOID || 'ZCTA_' + feature.properties.GEOID20;
                let land_area = feature.properties.ALAND || feature.properties.ALAND20;
                if (!land_area || land_area == 0) {
                    return;
                }

                if (skip.includes(id)) {
                    return;
                }
                else if (!within(id, parent_id)) {
                    return;
                }
                this.places[parent_id].child_ids.add(id);

                feature.properties.id = id;
                if (feature.properties.NAME) {
                    feature.properties.name = feature.properties.NAME;
                }
                
                // TODO blocks need name
                else if (feature.properties.NAME20) {
                    feature.properties.name = feature.properties.NAME20;
                }

                // one zip code can be fetched twice
                // only add it to the map once
                if (!seen_ids.has(id)){
                    result.features.push(feature);
                    seen_ids.add(id);
                }

                this.places[id] ||= {properties: null};
                this.places[id].properties = feature.properties;

                // set feature.properties.value last, after properties are cached locally 
                // because this cache survives a data set change
                let value = values[id];
                let norm = norm_values[id];
                let base = raw_values[id];

                // decennial data is already present in geojson, just access the property
                if (decennial) {
                    let parts = this.map.data_set.key.split('~');
                    let key = parts[0];
                    let normalize_key = null;
                    if (parts.length > 1 && !normalize_by_land) {
                        normalize_key = parts[1];
                    }
                    
                    if (key == 'POP20') {
                        key = 'P20';
                    }
                    else if (key == 'HOUSING20') {
                        key = 'H20';
                    }
                    value = feature.properties[key];
                    base = value;
                    if (normalize_key) {
                        norm = feature.properties[normalize_key];
                        if (norm == 0) {
                            value = null;
                        }
                        else {
                            value /= norm;
                        }
                    }
                    else {
                        norm = null;
                    }
                }
                
                if (value === undefined || value === null || value < 0) { 

                    value = null;
                }
                else if (normalize_by_land) {
                    norm = feature.properties['ALAND'] || feature.properties['ALAND20'];
                    if (!norm) {
                        console.error('No land area for ' + id);
                        value = null;
                    }
                    else if (value) {
                        // sq meters to sq miles
                        let land_sq_mi = norm / 2589988.11;
                        value /= land_sq_mi;
                    }
                }
                feature.properties.value = value ? value : null;
                feature.properties.norm = norm;
                feature.properties.base = base;
            });
        }
        return result;
    }

    getChildIds(place_id: string): string[] {
        return Array.from(this.places[place_id].child_ids);
    }

    getPlaceInfo(id: string) {
        if (id == '0') {
            return constants.CONUS_PROPERTIES;
        }
        else if (this.places[id]) {
            return this.places[id].properties;
        }
        else {
            return null;
        }
    }

    async geocode(address: string){
        // TODO implement some caching around this
        return API.geocode(address);
    }

    async findCounty(target: LatLon): Promise<string> {
        let counties = await this.getShapes([constants.CONUS_ID], Level.County);
        for (var i = 0; i < counties.features.length; i++) {
            let box = counties.features[i].geometry.bbox;
            let [sw_lon, sw_lat, ne_lon, ne_lat] = box;
            let bounds = new Leaflet.LatLngBounds([sw_lat, sw_lon], [ne_lat, ne_lon]);
            if (bounds.contains( <any> target)) {
                return counties.features[i].properties.id;
            }
        }
        return null;
    }

    private async getChildData(place_id: string, level: Level) {
        if (skip.includes(place_id)) {
            return <any> {};
        }

        let components = this.map.data_set.key.split('~');
        let normalize_by = null;
        if (components.length > 1 && !components[1].includes('LAND_AREA')) {
            normalize_by = components[1];
            if (![11,12].includes(normalize_by.length)) {
                console.error('Invalid data key: Invalid normalization component: ' + normalize_by );
                return;
            }
        }
        let component_vars_str = components[0];
        let component_vars = component_vars_str.split(',');
        for (let component_var of component_vars) {
            if (![11,12].includes(component_var.length)) {
                console.error('Invalid data key: Invalid additive component: ' + component_var );
                return;
            }
        }

        let vintage = this.map.data_set.vintage;
        let data_product = this.map.data_set.data_product;

        let promises = component_vars.map( (component_var) => API.fetchChildData(place_id, level, component_var, vintage, data_product) );
        if (normalize_by) {
            promises.push(API.fetchChildData(place_id, level, normalize_by, vintage, data_product));
        }
        let results = await Promise.all(promises);
        let normalize_result = null;
        if (normalize_by) {
            normalize_result = results.pop();
        }
        let combined_results = {};
        let raw_results = {};
        for (var key in results[0]) {
            let val = Number(results[0][key]);
            combined_results[key] = val;
            raw_results[key] = val;
            for (let i = 1; i < results.length; i++) {
                val = Number(results[i][key]);
                combined_results[key] += val;
                raw_results[key] = val;
            }
            if (normalize_result) {
                val = Number(normalize_result[key]);
                if (val == 0) {
                    // would divide by zero - set to null
                    combined_results[key] = null;
                }
                else {
                    combined_results[key] /= val;
                }
            }
        }
        return {
            combined: combined_results,
            normalize: normalize_result,
            raw: raw_results
        };
    }

    private async getChildren(place_id: string, level: Level) {
        if (skip.includes(place_id)) {
            return <any> {};
        }

        let parent_level = findLevel(place_id);
        if (parent_level == Level.County && level == Level.Block) {
            let county_zip_codes = await API.fetchChildShapes(place_id, Level.ZipCode);
            let blocks = await Promise.all(county_zip_codes.features.map((zip_code) => {
                let zip_code_id = zip_code.properties.GEOID20;
                let target = `ZCTA_${zip_code_id}`;
                return API.fetchChildShapes(target, Level.Block);
            }));
            let features = blocks.flatMap((block) => block.features);
            features = features.filter((feature) => {
                let block_id = feature.properties.GEOID;
                let county_id = block_id.substring(0, 5);
                return county_id == place_id;
            })
            return <FeatureCollection> {
                type: 'FeatureCollection',
                features: features
            };
        }
        else if (parent_level == Level.Country && level == Level.ZipCode) {
            let states = await API.fetchChildShapes(place_id, Level.State);
            let state_ids = states.features.map((state) => state.properties.GEOID);
            let zip_codes = await Promise.all(state_ids.map((state_id) => API.fetchChildShapes(state_id, Level.ZipCode)));
            let features = zip_codes.flatMap((zip_code) => zip_code.features);
            // deduplicate
            let seen = new Set();
            features = features.filter((feature) => {
                let id = feature.properties.GEOID20;
                if (seen.has(id)) {
                    return false;
                }
                seen.add(id);
                return true;
            });
            return <FeatureCollection> {
                type: 'FeatureCollection',
                features: features
            };
        }

        return await API.fetchChildShapes(place_id, level);
    }


    // Populate cache by requesting everything, from an env with the credentials to do so

    async fetchShapesForState(state: string, recursive: boolean, completed: any) {
        console.info(`Fetch shapes within ${state}`);

        // fetch county and zipcode data
        await this.getShapesWithData([state], Level.County);
        await this.getShapesWithData([state], Level.ZipCode);
        await this.wait(2);
        
        if (!recursive) {
            return;
        }

        // fetch tract and block-group data
        let CHUNK_SIZE = 5;
        let levels = [Level.Tract, Level.BlockGroup, Level.ZipCode];
        let children_of_state = this.getChildIds(state);
        let counties = children_of_state.filter(id => id.length==5);

        for (var i = 0; i < counties.length; i+=CHUNK_SIZE) {
            let end = Math.min(counties.length, i + CHUNK_SIZE);

            let slice = counties.slice(i, end);
            for (var l = 0; l < levels.length; l++) {
                let level = levels[l];
                await Promise.all(
                    slice.map( c => this.getShapesWithData([c], level))
                );
            }
            await this.wait(3);
            console.info(`Fetched a batch of counties within ${state}`)
            console.info(`States completed: ${completed}`)
        }
    }

    async wait(seconds) {
        console.info(`will wait ${seconds} seconds`)
        await new Promise(resolve => setTimeout(resolve, seconds * 1000));
    }

    async fetchAllShapes(states_completed: number = 0, recursive: boolean = false) {
        if (!(process.env.CENSUS_API_KEY && process.env.CACHE_TOKEN)) {
            console.info('Missing credentials to populate cache');
            return;
        }
        else {
            // can safely assume that states-within-country have been fetched with initial page load
            let states = this.getChildIds('0');
            states.sort();
            states = states.slice(states_completed);
            let completed = states_completed;

            for (var i = 0; i < states.length; i++) {
                let state = states[i];
                await this.fetchShapesForState(state, recursive, completed);
                completed += 1;
            }
        }

    }
}