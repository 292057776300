import { useEffect, useRef, useState } from 'react';
import { Map } from './map';
import { Level, DataSet } from './types'

type MapComponentProps = {
    div_id: string,
    raw_query: string,
    confirmed: boolean,
    res_data_product: string,
    res_uom: string,
    res_vintage: string,
    res_var: string,
    res_var_name: string,
    res_scope: string[],
    spatial_resolution: Level,
    location: [number, number],
    className: string,
    setMap: (map: any) => void,
    allMapsRef: React.MutableRefObject<any[]>,
    forceTotalPopulation: () => void
}
export function MapComponent(props: MapComponentProps) {
    const selected_var = props.res_var ? props.res_var.split('|')[0] : null;
    const selected_vintage = props.res_vintage ? props.res_vintage.split('|')[0] : null;
    const mapRef = useRef(null);
    useEffect(() => {
        // we never update a map - once created, a map updates itself, or is updated by the other map, or is destroyed by a new search
        if (props.confirmed && !mapRef.current) {
            let map_data_info = {
                map_title: props.res_var_name,
                data_set: {
                    key: selected_var,
                    name: props.res_var_name,
                    uom: props.res_uom,
                    vintage: selected_vintage,
                    data_product: props.res_data_product
                },
                scope: props.res_scope,
                spatial_resolution: props.spatial_resolution,
                location: props.location
            }

            const map = new Map(props.div_id, map_data_info, props.forceTotalPopulation);
            mapRef.current = map;
            props.setMap(map);
        }
        else if (props.confirmed && mapRef.current) {
            let current_map_data_set = mapRef.current.data_set;
            if (current_map_data_set.key != selected_var || current_map_data_set.vintage != selected_vintage) {
                let new_data_set = {
                    key: selected_var,
                    name: props.res_var_name,
                    uom: props.res_uom,
                    vintage: selected_vintage,
                    data_product: props.res_data_product
                }
                mapRef.current.data_set = new_data_set;
                mapRef.current.scope.update(mapRef.current.scope.place_ids, mapRef.current.scope.spatial_resolution).then((success) => {
                    if (success) {
                        mapRef.current.update(false);
                    }
                });
            }
        }
    }, [props.res_var, props.res_var_name, props.res_scope, props.res_vintage, props.confirmed, props.res_data_product]);

    useEffect(() => {
        return () => {
            if (mapRef.current && mapRef.current.leaflet_map) {
                let mapsRef = props.allMapsRef.current;
                if (mapsRef[0] && mapsRef[1]) {
                    mapsRef[0].leaflet_map.unsync(mapsRef[1].leaflet_map);
                    mapsRef[1].leaflet_map.unsync(mapsRef[0].leaflet_map);

                    mapsRef[0].syncDataUpdates(null);
                    mapsRef[1].syncDataUpdates(null);
                }
                mapRef.current.leaflet_map.remove()
                mapRef.current = null;
                props.setMap(null);
            }
        };
    }, []);

    return (

        <div>
            <div id={props.div_id} className={props.className}/>
        </div>
    );
}