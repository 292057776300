import { constants } from '../helpers/constants';
import * as Leaflet from 'leaflet';
import { Map } from '../map';
import { Feature } from 'geojson';
import { Level } from '../types';

export class PopUp {
    map: Map;
    layer: Leaflet.GeoJSON;
    feature: Feature;

    constructor(map: Map, layer: Leaflet.GeoJSON) {
        this.map = map;
        this.layer = layer;
        this.feature = <Feature> layer.feature;
        this.open();
    }

    async open() {
        let popup =  Leaflet.popup({
            pane: 'highlight'
        });
        popup.setLatLng(this.location())
        popup.setContent(await this.content())

        popup.on('remove', () => {
            this.map.scope.clearHighlight();
            if (this.map.sync_to) {
                this.map.sync_to.leaflet_map.closePopup();
            }
        });

        popup.openOn(this.map.leaflet_map);
    }

    pretty(value: number) {
        if (value === null || value === undefined) {
            return 'N/A';
        }
        value = parseFloat(value.toString());
        let precision = 3;
        if (value > 10) {
            precision = 1;
        }
        else if (value > 1) {
            precision = 2;
        }
        let repr = value.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        repr = repr.replace(/\.?0*$/, '');
        return repr;
    }
    async content() {
        let properties = this.feature.properties;
        let value = properties.value;
        if (!value) {
            value = 'N/A';
        }
        else if (this.map.data_set.key.includes('~') && !this.map.data_set.key.includes('~LAND_AREA')) {
            value = (value * 100).toFixed(1) + '%';
        }
        else if (this.map.data_set.key.includes('~LAND_AREA')) {
            value = value.toFixed(1) + ' per mi²';
        }
        let name = properties.name;
        let data_name = this.map.data_set.name;
        data_name = data_name.replace(' (2020 Decennial Census)', '');
    
        if (this.map.data_set.key.includes('~LAND_AREA')) {
            data_name += ' Density (per mi²)';
        }
        else if (this.map.data_set.key.includes('~')) {
            data_name += ' (Percent of Total)';
        }
    
        let content = Leaflet.DomUtil.create('div');
        content.style.fontFamily = 'Arial, sans-serif';
        content.style.padding = '5px';
    
        if (name) {
            content.innerHTML = `<div style="text-align: center; font-size: 12px; margin-bottom: 5px;">${name}</div>`;
        } else {
            content.innerHTML = '';
        }
        content.innerHTML += `
            <div style="font-size: 12px; font-weight: bold;">${data_name}</div>
            <div style="font-size: 18px; margin: 3px 0;">${this.pretty(value)}</div>
        `;
    
        let expression = this.map.data_set.key; 
        let others = [];
        if (expression.includes('~')) {
            others.push({name: this.map.data_set.name, value: properties.base})
    
            let normalize_expression = expression.split('~')[1];
            let name = null; 
            let value = null;
            if (normalize_expression === 'P20') {
                name = 'Total Population (2020 Decennial Census)';
                value = properties.P20;
            }
            else if (normalize_expression === 'H20') {
                name = 'Housing Units (2020 Decennial Census)';
                value = properties.H20;
            }
            else if (normalize_expression === 'O20') {
                name = 'Occupied Housing Units (2020 Decennial Census)';
                value = properties.O20;
            }
            else if (normalize_expression === 'V20') {
                name = 'Vacant Housing Units (2020 Decennial Census)';
                value = properties.V20;
            }
            else if (normalize_expression === 'LAND_AREA') {
                name = 'Land Area (mi²)';
                value = properties.ALAND || properties.ALAND20;
                value = (value / 2589988.11).toFixed(3);
            }
            else {
                name = `Total (${normalize_expression})`;
                value = properties.norm;
            }
            others.push({name: name, value: value});
        }
    
        if (!expression.includes('P20') && !others.some(other => other.name === 'Total Population (2020 Decennial Census)')) {
            others.push({name: 'Total Population (2020 Decennial Census)', value: properties.P20});
        }
    
        for (let other of others) {
            // remove "(2020 Decennial Census)" from the name
            other.name = other.name.replace(' (2020 Decennial Census)', '');
            content.innerHTML += `
                <div style="font-size: 12px; font-weight: bold; margin-top: 5px;">${other.name}</div>
                <div style="font-size: 18px; margin-bottom: 3px;">${this.pretty(other.value)}</div>
            `;
        }
    
        if ([Level.Tract, Level.BlockGroup, Level.ZipCode].includes(this.map.scope.spatial_resolution)) {
            let target_button = this.button('Block-Level Data', this.div(content))
            target_button.onclick = async () => {
                if (!['P20', 'H20', 'O20', 'V20'].includes(this.map.data_set.key)) {
                    let prev_name = this.map.data_set.name;
                    let result = confirm(`This indicator (${prev_name}) is unavailable for blocks - switch to Total Population (2020 Decennial Census)?`)
                    if (!result) {
                        return;
                    }
                    await this.map.forceTotalPopulation();
                }
                await this.map.zoomTo([properties.id]);
            }
        }
        else if (![Level.Block].includes(this.map.scope.spatial_resolution)) {
            let target_button = this.button('Zoom To ' + name, this.div(content));
            target_button.onclick = () => {
                this.map.zoomTo([properties.id]);
            }
        }
    
        return content;
    }

    button(text: string, parent: HTMLElement) {
        let button = Leaflet.DomUtil.create('button', '', parent);
        button.innerHTML = text;
        button.style.marginTop = '5px';
        button.style.padding = '3px 8px';
        button.style.fontSize = '12px';
        button.style.cursor = 'pointer';
        return button;
    }

    div(parent : HTMLElement) {
        return Leaflet.DomUtil.create('div', '', parent);
    }

    location() {
        let id = this.feature.properties.id;
        if (id in constants.CUSTOM_POPUP_LOCATIONS) {
            return constants.CUSTOM_POPUP_LOCATIONS[id];
        }
        return {
            lng: this.layer.getBounds().getCenter().lng,
            lat: this.layer.getBounds().getNorth()
        };
    }
}