import * as Leaflet from 'leaflet';
import { Map } from '../../map';
import { DataLabel } from './data_label';
import { ZoomOptions } from './zoomOptions';

export class ControlPanel {
    map: Map;
    leaflet_element: Leaflet.Control;
    zoom_options: ZoomOptions;
    data_label: DataLabel;

    constructor(map: Map) {
        this.map = map;
        this.leaflet_element = null; 
    }

    async update() {
        this.remove();
        await this.createElement();
        this.add();
        // legend must be re-drawn every time panel changes, to show up below
        // TODO: maybe legend should just belong to control panel
        this.map.legend.update();
    }

    async createElement() {
        let ele = new Leaflet.Control({position: 'topleft'});
        this.leaflet_element = ele;

        ele.onAdd = (map) => {
            let div = Leaflet.DomUtil.create('div','info control-panel');

            // this.data_label = new DataLabel(this.map, div);
            this.zoom_options = new ZoomOptions(this.map, div);

            return div;
        };
    }

    // buttons may be removed immediately after click
    // call stopPropagation to prevent a null error
    handleShowOverlayClick(ev: MouseEvent) {
        ev.stopPropagation();
        this.map.unShowBase(); 
    }

    handleHideOverlayClick(ev: MouseEvent) {
        ev.stopPropagation();
        this.map.showBase();
    }

    createButton(text: string, parent: HTMLElement) {
        let div = Leaflet.DomUtil.create('div', '', parent);
        let button = Leaflet.DomUtil.create('button', '', div);
        button.innerHTML = text;
        return button;
    }

    add() {
        if (this.leaflet_element) {
            this.leaflet_element.addTo(this.map.leaflet_map);
        }
    }
    remove() {
        if (this.leaflet_element) {
            this.map.leaflet_map.removeControl(this.leaflet_element);
            this.leaflet_element = null;
        }
    }
}