import { SearchInput, InputType, SearchData, IndicatorQuery, showAs, fetch_json, API_URL } from './utils';
import { styles } from './styles';
import { MapComponent } from '../map_component';
import { ResultFinder } from './result_finder';
import { ParsedQuery } from './parsed_query';
import { UrlInfo } from '../helpers/handle_url';
import { AlternativeMapSelector } from './alternative_maps';
import { useState, useRef, useEffect } from 'react';
import { Level } from '../types';


type SearchComponentProps = {
    data: SearchData;
    update: (new_data: any) => void;
};

type SearchMachineProps = {
    url_info: UrlInfo;
}

// TODO require UOM eventually, once plugin and backend always provide it
const REQUIRED_RESULT_KEYS = [
    'data_product',
    'expression',
    'name',
    'vintage',
    'uom',
    'meta',
]
const REQUIRED_SEARCH_DATA_KEYS = [
    'spatial_resolution',
    'scope'
]


function setConfirmed(search_data: SearchData) {
    if (!search_data.requested_indicators) {
        return;
    }

    for (let i = 0; i < REQUIRED_SEARCH_DATA_KEYS.length; i++) {
        if (!(REQUIRED_SEARCH_DATA_KEYS[i] in search_data) || search_data[REQUIRED_SEARCH_DATA_KEYS[i]] === null) {
            return;
        }
    }
    
    let indicators = search_data.requested_indicators;
    for (let j = 0; j < indicators.length; j++) {
        let can_confirm = true; 
        let result = search_data.results[j];
        if (!result) {
            can_confirm = false;
        }
        else {
            for (let i = 0; i < REQUIRED_RESULT_KEYS.length; i++) {
                if (!(REQUIRED_RESULT_KEYS[i] in result) || result[REQUIRED_RESULT_KEYS[i]] === null) {
                    can_confirm = false;
                }
            }
        }
        if (can_confirm) {
            result.confirmed = true;
        }
    }
}

function update(setState: any, new_state: any) {
    setState(prev_state => {

        // hack: if new_state['only_index'] is set, we only want to update the requested_indicators and results at that index
        let new_data;;
        if (new_state['only_index'] !== undefined) {
            let index = new_state['only_index'];
            
            new_data = {...prev_state};

            if (new_state.requested_indicators) {
                let new_indicator = new_state.requested_indicators[index];
                new_data.requested_indicators[index] = new_indicator;
            }

            if (new_state.results) {
                let new_result = new_state.results[index];
                new_data.results[index] = new_result;
            }
            // if 'warning message' is a key (even if it's null), we want to update it
            if ('warning_message' in new_state) {
                new_data.warning_message = new_state.warning_message;
            }
            if ('error_message' in new_state) {
                new_data.error_message = new_state.error_message;
            }
            
        }
        else {
            new_data = {...prev_state, ...new_state};
        }


        setConfirmed(new_data);
        return new_data;
    });
}

export function SearchMachine(props: SearchMachineProps) {
    let url_info = props.url_info;
    let initial_state:SearchData =  {

        raw_q: null,
        requested_indicators: null,
        scope: null,
        spatial_resolution: null,
        scope_name: null,
        location: null,

        results: [],

        error_message: null,
        warning_message: null
    }

    if (url_info) {
        initial_state.raw_q = url_info.data_set.name;
        let show_as = showAs(url_info.data_set.name);
        if (url_info.data_set.key.includes('~')) {
            show_as = 'percent_of_total';
        }

        let indicator:IndicatorQuery= {
            var_q: url_info.data_set.name,
            vintage: url_info.data_set.vintage || 'LATEST_AVAILABLE',
            show_as: show_as,
        }
        initial_state.scope = url_info.scope, 
        initial_state.spatial_resolution = url_info.spatial_resolution,
        initial_state.requested_indicators = [indicator];
    }

    const [search_data, setSearchData] = useState<SearchData>(initial_state);

    const [maps, setMaps] = useState<any>([]);
    const [synced, setSynced] = useState(false);

    if (maps.length > 1 && maps[0] && maps[1]) {
        if (!synced) {
            maps[0].leaflet_map.sync(maps[1].leaflet_map);
            maps[1].leaflet_map.sync(maps[0].leaflet_map);
            maps[0].syncDataUpdates(maps[1]);
            maps[1].syncDataUpdates(maps[0]);
            setSynced(true);
        }
    }
    else if (synced) {
        setSynced(false);
    }

    return (
        <div>
            <h2 style={{ textAlign: 'center', marginBottom: '5px' }}>BlockAtlas</h2>
            <h3 style={{ textAlign: 'center', marginBottom: '5px' }}>Search and Map US Demographic Data</h3>

            <div style={styles.container}>
                <div style={styles.searchContainer}>
                    <RawQuery data={search_data} update={new_data => update(setSearchData, new_data)}/>
                    <ParsedQuery data={search_data} update={new_data => update(setSearchData, new_data)}/>
                </div>
            </div>

            {
                search_data.requested_indicators && search_data.requested_indicators.length > 0 &&
                <SingleResult 
                    result_index={0} 
                    maps={maps} 
                    setMaps={setMaps} 
                    search_data={search_data} 
                    update={new_data => update(setSearchData, new_data) }/>
            }
            {
                search_data.requested_indicators && search_data.requested_indicators.length > 1 &&
                <SingleResult result_index={1}
                    search_data={search_data}
                    maps={maps} 
                    setMaps={setMaps} 
                    update={new_data => update(setSearchData, new_data) }/>
            }

        </div>
    );
};

type SingleResultProps = {
    search_data: SearchData;
    update: (new_data: any) => void;
    result_index: number;
    maps: any[];
    setMaps: (new_maps: any[]) => void;
};

function SingleResult(props: SingleResultProps) {
    let search_data = props.search_data;
    let result_index = props.result_index;
    let map_div_id = 'map' + result_index;
    let map_class;
    if (search_data.requested_indicators && search_data.requested_indicators.length > 1) {
        map_class = 'smallmap';
    }
    else {
        map_class = 'map';
    }

    let mapsRef = useRef(props.maps);
    useEffect(() => {
        mapsRef.current = props.maps;
    }, [props]);

    return (
        <div>
            <div style={styles.container}>
                <div style={styles.contentContainer}>
                    <div style={styles.formContainer}>
                        {search_data.error_message && <div style={styles.error}>{search_data.error_message}</div> }
                        {search_data.warning_message && <div style={styles.warning}>{search_data.warning_message}</div> }
                        <ResultFinder result_index={result_index} data={search_data} update={new_data => props.update(new_data)}/>
                    </div>

                    <div style={styles.mapContainer}>
                        { search_data.results[result_index]?.confirmed &&
                            <>
                                <MapComponent 
                                    div_id={map_div_id}
                                    raw_query={search_data.raw_q}
                                    spatial_resolution={search_data.spatial_resolution}
                                    res_scope={search_data.scope}
                                    res_vintage={search_data.results[result_index]?.vintage}
                                    confirmed={search_data.results[result_index]?.confirmed}
                                    res_var={search_data.results[result_index]?.expression}
                                    res_var_name={search_data.results[result_index]?.name}
                                    res_data_product={search_data.results[result_index]?.data_product}
                                    res_uom = {search_data.results[result_index]?.uom} 
                                    location = {search_data.location}
                                    className={map_class}
                                    setMap={(map:any)=>{
                                        let new_maps: any[]= [...mapsRef.current];
                                        new_maps[result_index] = map;
                                        props.setMaps(new_maps);
                                    }}
                                    allMapsRef={mapsRef}
                                    forceTotalPopulation={() => {
                                        let new_results = [...search_data.results];
                                        return fetch_json(API_URL + 'expression_metadata?expression=P20').then(meta => {
                                            new_results[result_index] = {
                                                expression: 'P20~LAND_AREA',
                                                name: 'Total Population (2020 Decennial Census)',
                                                data_product: 'decennial_census',
                                                vintage: '2020',
                                                uom: 'people',
                                                meta: meta,
                                                alternative_maps: null,
                                                confirmed: true,
                                                failed: false
                                            }
                                            props.update({
                                                only_index: result_index,
                                                results: new_results
                                            });

                                            // wait .2 seconds for the map to update (there's no API call to wait for)
                                            return new Promise((resolve, reject) => {
                                                setTimeout(() => resolve(true), 200);
                                            });
                                        })
                                    }}
                                />
                                {search_data.results.length === 1 &&
                                    <div style={styles.mapInstructionsContainer}>
                                        Click a place on the map to see details, or double-click to see within it. Clicking nearby places will add them to the map.
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            <div style={styles.container}>
                <AlternativeMapSelector result_index={result_index} data={search_data} update={new_data => props.update(new_data)}/>
            </div>
        </div>
    );
}

function RawQuery(props: SearchComponentProps) {
    let input_type:InputType;
    // If raw_q is null, this is an empty form. We'll show just the search bar.
    if (!props.data.raw_q) {
        input_type = 'normal';
    }
    else {
        input_type = 'semi_frozen';
    }
    let empty_search:SearchData =  {

        raw_q: null,
        requested_indicators: null,
        scope: null,
        spatial_resolution: null,
        scope_name: null,
        location: null,

        results: [],

        error_message: null,
        warning_message: null
    }

    return (
        <div style={styles.row}>
            <SearchInput 
                input_type={input_type}
                initialValue={props.data.raw_q}
                style={styles.fullWidth}
                label="search"
                placeholder="Zip code or street address for detailed population density, or search for something else (e.g. 'median income by county in CA')"
                // placeholder='Search an address, place (e.g. "90210" or "Beverly Hills") or topic (e.g. "Median Income by County in CA" or "Homeownership Rate")'
                updateValue={val=> {
                    props.update({ ...empty_search, raw_q: val });
                }}
            />
        </div>
    );
}
