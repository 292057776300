import { findLevel, defaultChild, parentLevel, uniqueParents, findAncestors } from '../helpers/levels';
import { Map } from '../map';
import { Level } from '../types';
import { DataLayer } from './data_layer';

export class MapScope {
    map: Map;
    place_ids: string[];
    active_layer: DataLayer;
    spatial_resolution: Level;

    constructor(map: Map) {
        this.map = map;
    }

    async update(new_scope: string[], target_spatial_resolution?: Level) {
        let valid_scope = await this.validScope(new_scope);
        if (!valid_scope) {
            return false;
        }

        let valid_spatial_resolution = this.validSpatialResolution(new_scope, target_spatial_resolution);

        if (!valid_spatial_resolution) {
            target_spatial_resolution = this.decideSpatialResolution(new_scope);
        }

        this.place_ids = new_scope;

        this.clearHighlight();
        let data = await this.map.places.getShapesWithData(new_scope, target_spatial_resolution);

        if (!data.features || !data.features.length) {
            console.error('invalid scope: no data. No features fetching ' + target_spatial_resolution + ' for provided scope ' + new_scope)
            return false;
        }

        if (this.active_layer){
            this.active_layer.remove();
            this.active_layer = null;
        }

        this.spatial_resolution = target_spatial_resolution;
        this.active_layer = new DataLayer(this.map, data);
        this.updateLayer();
        return true;
    }

    validSpatialResolution(new_scope, new_spatial_resolution) {
        if (!new_spatial_resolution) {
            return false;
        }
        let first = new_scope[0];
        let spatial_resolution = findLevel(first);
        if (spatial_resolution == Level.Country) {
            return [Level.State, Level.County, Level.ZipCode].includes(new_spatial_resolution);
        }
        else if (spatial_resolution == Level.State) {
            return [Level.County, Level.ZipCode].includes(new_spatial_resolution);
        }
        else if (spatial_resolution == Level.County) {
            return [Level.Tract, Level.BlockGroup, Level.ZipCode, Level.Block].includes(new_spatial_resolution);
        }
        else if ([Level.Tract, Level.BlockGroup, Level.ZipCode].includes(spatial_resolution)) {
            return new_spatial_resolution == Level.Block;
        }
        else {
            throw Error('Unexpected spatial_resolution')
        }
    }
    
    async validScope(new_scope) {
        let first = new_scope[0];
        let spatial_resolution = findLevel(first);
        // spatial_resolution is a valid scope
        if (![Level.County, Level.State, Level.Country, Level.Tract, Level.BlockGroup, Level.ZipCode].includes(spatial_resolution)) {
            console.error('invalid scope: invalid spatial_resolution. Provided scope ' + new_scope + ' has spatial_resolution ' + spatial_resolution)
            return false;
        }

        // all ids have same spatial_resolution
        for (var i = 1; i < new_scope.length; i++) { 
            let place = new_scope[i];
            if (findLevel(place) != spatial_resolution) {
                console.error('invalid scope: inconsistent spatial_resolutions. Provided scope ' + new_scope + ' has spatial_resolution ' + spatial_resolution)
                return false;
            }
        }


        // ensure all ancestors are known to the map, fetch if needed
        let ancestors = findAncestors(new_scope);
        let ancestor_place_ids = ancestors.flat(1);
        for (let ancestor of ancestor_place_ids) {
            let spatial_resolution = findLevel(ancestor);
            if (spatial_resolution == Level.Country) {
                let res = await this.map.places.getShapesWithData([ancestor], Level.State)
                if (!res.features || !res.features.length) {
                    console.error('unable to fetch ancestor ' + ancestor + ' for scope ' + new_scope)
                    return false;
                }
            }
            else if (spatial_resolution == Level.State) {
                let res = await this.map.places.getShapesWithData([ancestor], Level.County)
                if (!res.features || !res.features.length) {
                    console.error('unable to fetch ancestor ' + ancestor + ' for scope ' + new_scope)
                    return false;
                }
            }
            else if (spatial_resolution == Level.County) {
                let res = await this.map.places.getShapesWithData([ancestor], Level.Tract)
                if (!res.features || !res.features.length) {
                    console.error('unable to fetch tracts for county ' + ancestor + ' for scope ' + new_scope)
                    return false;
                }
                res = await this.map.places.getShapesWithData([ancestor], Level.ZipCode)
                if (!res.features || !res.features.length) {
                    console.error('unable to fetch zipcodes for county ' + ancestor + ' for scope ' + new_scope)
                    return false;
                }
            }
            else {
                console.error("Missing place information, and unable to recover.")
                return false;
            }
        }
        return true;
    }

    decideSpatialResolution(new_scope) {
        // if map is loading for the first time
        if (!this.spatial_resolution) {
            let id_size = new_scope[0].length;
            // show counties within a state
            if (id_size == 2) {
                return Level.County;
            }
            else if (id_size == 5) {
                return Level.Tract;
            }
            else {
                return Level.State;
            }
        }
        // when adding a neighbor, do not change spatial_resolution
        else if (new_scope.includes(this.place_ids[0])) {
            return this.spatial_resolution;
        }
        else {
            let target_spatial_resolution = findLevel(new_scope[0]);
            return defaultChild(target_spatial_resolution);
        }
    }

    canZoomOut() {
        return this.spatial_resolution !== Level.State;
    }

    async zoomOut() {
        let new_spatial_resolution = parentLevel(this.spatial_resolution);
        let new_scope;
        if (this.spatial_resolution == Level.BlockGroup) {
            // do a 'soft zoom' for block groups - do not get tracts in state
            new_scope = this.place_ids;
        }
        else {
            new_scope = uniqueParents(this.place_ids);
        }
        return await this.update(new_scope, new_spatial_resolution);
    }

    updateLayer() {
        if (this.active_layer) {
            this.active_layer.remove();
            this.active_layer.add();
        }
    }

    clearHighlight() {
        if (this.active_layer) {
            this.active_layer.clearHighlight();
        }
    }
}
