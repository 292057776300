import { DataSet, Level } from '../types';
import { findLevel, defaultChild } from './levels';
import { API } from './api';

export type UrlInfo = {
    scope: string[],
    scope_name: string,
    scope_type: Level,
    spatial_resolution: Level,
    data_set: DataSet
}

export async function handleUrl(): Promise<UrlInfo> {
    let url = new URL(window.location.href);
    let path = url.pathname;
    if (path.includes('/map/') || path.includes('/m/')) {
        let segments = path.split('/');
        let key = segments.pop() || segments.pop(); // Handle trailing slash
        let kv_key = `map_info_${key}`;
        let map_info = await API.fetchCache(kv_key);
        if (!map_info) {
            return null;
        }
        let scope = map_info['scope'].split(',')
        
        // TODO the plugin should start writing these so we don't have to guess
        let scope_type = findLevel(scope[0])
        let spatial_resolution = defaultChild(scope_type);
        let scope_name;
        if (map_info['scope_name']) {
            scope_name = map_info['scope_name'];
        }

        let data_set:DataSet= {
            key: map_info['variable'],
            name: map_info['map_title'],
            uom: null,
            vintage: map_info['vintage'] || '2022',
            // TODO there should be no implict acs5 - must update plugin
            data_product: map_info['data_product'] || 'acs5'
        }

        let res = {
            scope: scope,
            scope_name: scope_name,
            scope_type: scope_type,
            spatial_resolution: spatial_resolution,
            data_set: data_set,
        }
        return res;
    }
    else {
        return null;
    }
}