import { SearchData } from './utils';
import { parseVariableKey } from '../helpers/censusapi';
import { canNormalizeByLand, findUom } from './utils';

type NormalizeSelectorProps = {
    result_index: number;
    data: SearchData;
    update: (new_data: any) => void;
};

export function NormalizeSelector(props: NormalizeSelectorProps) {
    if (!props.data.results[props.result_index].confirmed || !props.data.results[props.result_index].meta) {
        return <div style={{display: 'none'}}></div>;
    }

    let meta = props.data.results[props.result_index].meta;
    let valid_options;
    let uom = props.data.results[props.result_index].uom;

    if (canNormalizeByLand(uom)) {
        valid_options = meta.shared_norms.concat(['LAND_AREA']);
    }
    else {
        valid_options = meta.shared_norms;
    }

    let variable_meta = meta.variable_meta;

    let normalize;
    let parts = props.data.results[props.result_index].expression.split('~');
    if (parts.length > 1) {
        normalize = parts[1];
    }
    else {
        normalize = null;
        if (!valid_options || valid_options.length === 0) {
            return <div style={{display: 'none'}}></div>;
        }
    }

    return (
        <div style={{marginTop:'10px'}} >
            <span>
                <strong>Percent / Ratio:</strong>
            </span>
            <div>
                <input 
                    type="radio"
                    name="normalize"
                    value=""
                    checked={!normalize}
                    onChange={(ev)=>{
                        let new_variable_id = updateNormalize(props.data.results[props.result_index].expression, null);
                        let new_uom = findUom(new_variable_id, props.data.results[props.result_index].meta);
                        let new_results = [...props.data.results];
                        new_results[props.result_index].uom = new_uom;
                        new_results[props.result_index].expression = new_variable_id;
                        props.update({results: new_results, only_index: props.result_index});
                    }}/>
                    None
            </div>
                {valid_options.map((option: any, i: number) =>
                        <div key={i}>
                            <input 
                                type="radio"
                                name={'normalize_result_' + props.result_index}
                                value={option}
                                checked={option === normalize}
                                onChange={(ev)=>{
                                    let new_normalize = ev.target.value;
                                    let new_variable_id = updateNormalize(props.data.results[props.result_index].expression, new_normalize);
                                    let new_uom = findUom(new_variable_id, props.data.results[props.result_index].meta)
                                    let new_results = [...props.data.results];
                                    new_results[props.result_index].uom = new_uom;
                                    new_results[props.result_index].expression = new_variable_id;
                                    props.update({results: new_results, only_index: props.result_index});
                                }}/>
                            {option}: {variable_meta[option].name}
                        </div>
                )}
        </div>
    )
}

function updateNormalize(old_variable_id: string, new_normalize: string) {
    let { additive_vars, normalize } = parseVariableKey(old_variable_id);
    if (!new_normalize || new_normalize === '') {
        return additive_vars.join(',');
    }
    let new_variable_id = additive_vars.join(',') + '~' + new_normalize;
    return new_variable_id;
}
