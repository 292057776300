import { Level } from '../types';
import { within} from '../helpers/levels';

// Get census GeoJSON, merged with total population. 
// This variable should always be available, so is cached with shapes
export async function shapes(parent_key: string, level: Level) {
    let path = geoJSONPath(parent_key, level);

    let res;
    let LOCAL_CACHE = (<any> window).LOCAL_CACHE;
    if (LOCAL_CACHE[path]){
        res = LOCAL_CACHE[path];
    }
    else {
        res = await fetch(path).then(res=>res.json());
        LOCAL_CACHE[path] = res;
    }

    let filtered_features = res.features.map(place => {
        let id = place.properties['GEOID'] || 'ZCTA_' + place.properties['GEOID20'];
        if (!within(id, parent_key)) {
            return null;
        }
        return place;
    }).filter(a=>a);

    return {
        type: 'FeatureCollection',
        features: filtered_features
    };
}

// this assumes state -> county -> tract 
// in the future, request may include a target geog argument
function geoJSONPath(parent_key: string, level: Level) {
    let state;
    switch (level) {
        case Level.State:
            return 'https://raw.githubusercontent.com/uscensusbureau/citysdk/master/v2/GeoJSON/20m/2021/state.json';
        case Level.County:
            return 'https://raw.githubusercontent.com/uscensusbureau/citysdk/master/v2/GeoJSON/20m/2021/county.json';
        case Level.Tract:
            state = parent_key.slice(0,2);
            return `https://raw.githubusercontent.com/uscensusbureau/citysdk/master/v2/GeoJSON/500k/2021/${state}/tract.json`;
        case Level.BlockGroup:
            state = parent_key.slice(0,2);
            return `https://raw.githubusercontent.com/uscensusbureau/citysdk/master/v2/GeoJSON/500k/2021/${state}/block-group.json`;
        case Level.ZipCode:
            return `https://raw.githubusercontent.com/uscensusbureau/citysdk/master/v2/GeoJSON/500k/2020/zip-code-tabulation-area.json`;

    }
}

const INFO = {
    'acs1': {
        base_url: 'https://api.census.gov/data/YYYY/acs/acs1?',
        subject_base_url: 'https://api.census.gov/data/YYYY/acs/acs1/subject?',
    },
    'acs5': {
        base_url: 'https://api.census.gov/data/YYYY/acs/acs5?',
        subject_base_url: 'https://api.census.gov/data/YYYY/acs/acs5/subject?',
    }
}

export async function data(parent: string, variable: string, level: Level, year: string, data_product: string) {
    let { base_url, subject_base_url } = INFO[data_product];
    let target_param = `get=NAME,${variable}`;
    let hierarchy_params = `&${getHierarchy(parent, level)}`;
    let url;
    if (variable[0]==('S')) {
        url = subject_base_url.replace('YYYY','year') + target_param + hierarchy_params;
    }
    else {
        url = base_url.replace('YYYY', year) + target_param + hierarchy_params;
    }
    if (process.env.CENSUS_API_KEY) {
        url += `&key=${process.env.CENSUS_API_KEY}`;
    }
    let LOCAL_CACHE = (<any> window).LOCAL_CACHE;
    let api_response = LOCAL_CACHE[url] || await fetch(url).then(res=>res.json());
    LOCAL_CACHE[url] ||= api_response;

    return transform(api_response, variable, parent, level);

}

function transform(api_response:any, variable_id: string, parent: string, level: Level) {
    let transformed_result = {};
    let headers = api_response[0];
    api_response.forEach( (val, i) => {
        if (i == 0) {
            // skip headers row of weird census csv-json
            return;
        }
        let get = (key) => {
            let index = headers.indexOf(key);
            return index < 0 ? '' : val[index];
        }

        let value = get(variable_id);

        let geoid;
        if (level == Level.ZipCode) { 
            geoid = 'ZCTA_' + get('zip code tabulation area');
        }
        else {
            // census returns ID fields broken out by level - must concatenate
            geoid = get('state') + get('county') + get('tract') + get('block group');
        }

        // reject data points for territories not within parent, because zcta is always national
        if (within(geoid, parent)){
            transformed_result[geoid] = value;
        }
    });
    return transformed_result;
}

function getHierarchy(parent_key, level) {
    let state, county;

    switch (level) {
        case Level.State: 
            return 'for=state:*';

        case Level.County:
            if (parent_key == '0') {
                return 'for=county:*';
            }
            return `for=county:*&in=state:${parent_key}`;
            
        case Level.Tract:
            state = parent_key.slice(0,2);
            county = parent_key.slice(2);
            return `for=tract:*&in=state:${state} county:${county}`;

        case Level.BlockGroup:
            state = parent_key.slice(0,2);
            county = parent_key.slice(2,5);
            return `for=block group:*&in=state:${state} county:${county}`

        case Level.ZipCode: 
            return 'for=zip code tabulation area:*';

        default:
            return null;
    }
}


export async function getVariableName(variable: string) {
    let url = `https://api.census.gov/data/2021/acs/acs5/variables/${variable}.json`;
    let response = await fetch(url);
    let data:any = await response.json();
    let concept = data.concept;
    let label = data.label;
    label = label.replaceAll('!!', ' ');
    label = label.replaceAll('Estimate', '');
    // if last char is colon, remove it
    if (label[label.length-1] == ':') {
        label = label.slice(0, label.length-1);
    }
    return `(${concept}):  ${label}`;
}

export function parseVariableKey(key: string) {
    let split = key.split('~');
    let additive = split[0];
    let normalize = split[1];
    let additive_vars = additive.split(',');
    return {
        "additive_vars": additive_vars,
        "normalize": normalize
    };
}