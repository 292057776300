import { MapStyle } from './map_style';
import { Map } from '../map';
import { Layer } from './layer';
import * as Leaflet from 'leaflet';
import { FeatureCollection } from 'geojson';
import { PopUp } from '../map_elements/popup';
import { findLevel } from '../helpers/levels';
import { Level } from '../types';

export class DataLayer extends Layer {
    map: Map;
    raw_data: FeatureCollection;
    map_style: MapStyle;
    leaflet_layer: Leaflet.GeoJSON;
    detail_highlight: Leaflet.GeoJSON;
    block_clicks: boolean;
    pop_up: PopUp;
    size: number;

    constructor(map: Map, data: FeatureCollection) {
        super();

        this.map = map;
        this.raw_data = data;
        this.size = data.features.length;

        this.map_style = new MapStyle(this.map, data);

        this.createLeafletLayer();
    }

    createLeafletLayer() {
        this.leaflet_layer = Leaflet.geoJSON(this.raw_data, {
            // need to explicitly tell typescript that layer is a geojson layer
            onEachFeature: (feature, layer: Leaflet.GeoJSON) => {
                let style = this.map_style.getStyle(feature);
                layer.setStyle(style);
                layer.on({click: (ev) => this.singleClickHandler(ev)});
                layer.on({dblclick: (ev) => this.handleZoomClick(ev)});
            },
            pane: 'base'
        });
    }

    async handleZoomClick(ev: Leaflet.LeafletMouseEvent) {
        // do not do leaflet's auto-zoom
        Leaflet.DomEvent.stopPropagation(ev);

        if (this.block_clicks) {
            return;
        }
        // block other click events for 0.6 seconds
        this.block_clicks = true;
        setTimeout(()=>{this.block_clicks = false;}, 600);

        let clicked_id = ev.target.feature.properties.id;

        let level = findLevel(clicked_id);
        if (level == Level.Block) {
            return;
        }
        if ([Level.ZipCode, Level.Tract, Level.BlockGroup].includes(level) && !['P20', 'H20', 'O20', 'V20'].includes(this.map.data_set.key)) {
            let prev_key = this.map.data_set.key;
            let prev_name = this.map.data_set.name;
            let prev_vintage = this.map.data_set.vintage;
            let available = ['P20', 'H20', 'O20', 'V20'];
            // if any of avail is substr of prev_key, do nothing
            if (!available.some( (avail) => prev_key.includes(avail))) {
                let result = confirm(`This indicator (${prev_name} - ${prev_vintage}) is unavailable for blocks - switch to Total Population (2020 Decennial Census)?`)
                if (!result) {
                    return;
                }
                await this.map.forceTotalPopulation();
            }
        }

        let success = await this.map.zoomTo([clicked_id]);
        if (!success) {
            console.error('failed to zoom to ' + findLevel(clicked_id));
        }
    }

    // to make sure a single click is not one of a double click
    singleClickHandler(ev: Leaflet.LeafletMouseEvent) {
        setTimeout(() => {
            if (!this.block_clicks) {
                this.handleDetailClick(ev);
            }
        }, 150);
    }

    async handleDetailClick(ev: Leaflet.LeafletMouseEvent) {
        let element = ev.target;
        this.openPopupOnElement(element, false);
    }

    async openPopupOnElement(element: any, from_sync: boolean) {
        this.highlightDetail(element);
        this.pop_up = new PopUp(this.map, element);
        if (this.map.sync_to && !from_sync) {
            let other_layer;
            this.map.sync_to.scope.active_layer.leaflet_layer.eachLayer((layer: any) => {
                if (layer.feature.properties.id == element.feature.properties.id) {
                    other_layer = layer;
                }
            })
            if (other_layer) {
                this.map.sync_to.scope.active_layer.openPopupOnElement(other_layer, true);
            }
            else {
                console.error('cannot find corresponding layer in sync_to for popup');
            }
        }
    }

    highlightDetail(element: Leaflet.GeoJSON) {
        let style = this.map_style.getHighlightStyle(element.feature);
        element.setStyle(style);
        this.detail_highlight = element;
    }

    clearHighlight() {
        if (this.detail_highlight) {
            let style = this.map_style.getStyle(this.detail_highlight.feature);
            this.detail_highlight.setStyle(style);
            this.detail_highlight = null;
        }
    }
}