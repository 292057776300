import { Variable } from '../types';
import { styles } from './styles';


type VarInfoRowProps = {
    active_vintage: string;
    variable: Variable;
    update?: (action: string) => void;
    actions: string[];
    click_action?: (action: Variable) => void;
};

export function VarInfoRow(props: VarInfoRowProps) {
    if (!props.variable) {
        return <div style={{display: 'none'}}></div>;
    }
    
    let explanation = `${props.variable.key}: ${props.variable.search_name}`
    
    return (
        <div>
            {
                props.actions.map((update_type, i) => (
                    <button type={'button'} key={i} style={styles.varInfoButton} onClick={() => props.update(update_type)}>{update_type}</button>
                ))
            }
            <span>
                {
                    props.click_action ?
                        <button type={'button'} style={styles.linkButton} onClick={() => props.click_action(props.variable)}>{explanation}</button>
                    :
                        <span>{explanation}</span>
                }
            </span>
        </div>
    )
}
