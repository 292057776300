import { FeatureCollection } from 'geojson';
import * as Leaflet from 'leaflet';

export interface LayerIdMap {
    [id: string] : Leaflet.Layer;
}

export interface DataRepo {
    [key: string] : DataRepoPlace;
}

export interface DataRepoPlace {
    children: FeatureCollection;
    children_data: any;
}

export interface PlaceTypeDict {
    [key: string] : PlaceType;
}

export interface PlaceType {
    name: string;
    parent_name: string;
}

export interface PlaceDict {
    [key: string] : any;
}

export interface Variable  {
    search_name: string,
    key: string,
    name: string,
    uom: string,
    table_name: string,
    universe: string,
}

export interface DataSet {
    key: string,
    name: string,
    uom: string,
    vintage: string,
    data_product: string
}

export enum Level {
    Country = 'country',
    State = 'state',
    County = 'county',
    Tract = 'tract',
    BlockGroup = 'block group',
    ZipCode = 'zip code tabulation area',
    Block = 'block'
}

export interface LatLon {
    lat: any;
    lon: any;
}