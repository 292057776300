import * as chroma from 'chroma-js';
import { FeatureCollection } from 'geojson';
import { Map } from '../map';

export class MapStyle {
    map: Map;
    geojson: FeatureCollection;
    values: number[];
    scale: any;
    num_categories: number;

    constructor(map, geojson) {
        this.map = map;
        this.geojson = geojson;
        this.getColorScale();
    }

    async getColorScale() {
        let values = this.geojson.features.map(feature => feature.properties.value);

        this.values = values.filter(val=>val).sort((a,b) => a - b);
        this.num_categories = 5;
        this.scale = chroma.scale('BuGn').domain(this.values, this.num_categories, 'quantiles');
    }

    getBuckets() {
        let res = [];
        let bucket_size;
        // if there are fewer features than expected categories, every feature gets one category
        if (this.values.length < this.num_categories) {
            bucket_size = 1;
            this.num_categories = this.values.length;
        }
        else {
            bucket_size = Math.ceil(this.values.length / this.num_categories); 
            if (bucket_size * this.num_categories > this.values.length) {
                this.num_categories = Math.ceil(this.values.length / bucket_size);
            }
        }

        for (let category = 0; category < this.num_categories; category++) {
            // first value in bucket
            let bucket_min = this.values[category * bucket_size];
            // last value in bucket
            let max_index = ((category+1) * bucket_size) - 1;
            if (max_index >= this.values.length) {
                max_index = this.values.length - 1;
            }
            let bucket_max = this.values[max_index];
            let color = this.scale(bucket_min);
            res.push([bucket_min, bucket_max, color]);
        }

        return res;
    }

    getStyle(feature) {
        return {
            fillColor: this.scale(feature.properties.value),
            weight: 0.5,
            opacity: 1,
            color: 'white',
            fillOpacity: 0.7
        };
    }

    getHighlightStyle(feature) {
        let style = {
            color: 'white', 
            weight: 3,
            opacity: 1
        };
        return {...this.getStyle(feature), ...style};
    }

    // style for e.g. state, when a county is clicked
    static getBackgroundStyle() {
        return {
            color: 'blue',
            weight: 4,
            fillOpacity: 0,
            opacity: .5
        };
    }

    static getNeighborStyle() {
        // invisible, but can still click/hover
        return {
            fillOpacity: 0,
            opacity: 0
        }
    }

    static getNeighborHighlightStyle() {
        return {
            color: 'grey',
            fillOpacity: 0.2,
            opacity: 0
        }
    }

    static getInvisibleStyle() {
        // does not draw the feature - cannot click / hover
        return {
            stroke: false,
            fill: false
        }
    }
}