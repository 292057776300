import { parseVariableKey } from '../helpers/censusapi';
import { SearchData, fetch_json, API_URL } from './utils';
import { styles } from './styles';
import { VarInfoRow } from './var_info_row';
import { useState } from 'react';

type SelectedVariableProps = {
    result_index: number;
    data: SearchData;
    update: (new_data: any) => void;
};

export function SelectedVariable(props: SelectedVariableProps) {
    let expression = props.data.results[props.result_index].expression;
    if (expression.includes('|')) {
        expression = expression.split('|')[0];
    }
    let { additive_vars, normalize } = parseVariableKey(expression);
    const [fetching, setFetching] = useState<boolean>(false);
    const [fetchedFor, setFetchedFor] = useState<string>(null);
    const [showMore, setShowMore] = useState<boolean>(false);

    if (!props.data.results[props.result_index].meta || props.data.results[props.result_index].expression !== fetchedFor) {
        if (!fetching) {
            setFetching(true);
            fetchMeta(props.data, props.result_index, props.update).then( () => {
                setFetching(false);
                setFetchedFor(props.data.results[props.result_index].expression);
            });
        }
        return <div style={{display: 'none'}}></div>;
    }

    let { variable_meta } = props.data.results[props.result_index].meta;
    let normalize_label = normalize ? variable_meta[normalize].search_name : null;
    let normalize_display = normalize_label ? `${normalize}: ${normalize_label}` : null;
    let active_var_title = props.data.results[props.result_index].name;

    return (
        <div style={{textAlign:'center'}}>
            <span style={{fontSize: '1.3em'}}> 
                <strong>{active_var_title}</strong> ({props.data.results[props.result_index].vintage})
            </span>
            
            {additive_vars.length > 1 && (
                <div style={{marginTop: '0'}}>
                    <button onClick={() => setShowMore(!showMore)}>
                        {showMore ? 'Hide Components' : 'Show Components'}
                    </button>
                    
                    {showMore && (
                        <ul style={styles.variableList}>
                            <li style={{fontSize:'1.1em'}}><strong>Sum Of:</strong><br></br></li>
                            {additive_vars.map((variable, i) => (
                                <li key={i} style={{fontSize:'1.1em'}}>
                                    <VarInfoRow 
                                        active_vintage={props.data.results[props.result_index].vintage}
                                        variable={variable_meta[variable]}
                                        actions={[]}
                                    />
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            )}
            {additive_vars.length === 1 && (
                <ul style={styles.variableList}>
                    {additive_vars.map((variable, i) => (
                        <li key={i} style={{fontSize:'1.1em'}}>
                            <VarInfoRow 
                                active_vintage={props.data.results[props.result_index].vintage}
                                variable={variable_meta[variable]}
                                actions={[]}
                            />
                        </li>
                    ))}
                </ul>
                
            )}
            {normalize_display && (
                <div style={{marginTop: '10px'}}>
                    <strong>Divided By: </strong>{normalize_display}
                </div>
            )}
        </div>
    )
}

async function fetchMeta(data: SearchData, result_index: number, update: (new_data: any) => void) {
    let expression = data.results[result_index].expression;
    let result = await fetch_json(API_URL + 'expression_metadata?expression=' + encodeURIComponent(expression),
        (error_resp) => { throw Error('Could not retrieve metadata for result expression: ' + expression) } 
    );

    let new_results = [];
    new_results[result_index] = {...data.results[result_index], meta: result};
    let update_obj = {
        results: new_results,
        only_index: result_index
    }

    update(update_obj);
}