export const styles: { [key: string]: any } = {
    mapInstructionsContainer: {
        textAlign: 'center',
        padding: '10px',
        fontSize: '14px',
        color: '#555',
    },
    linkButton: {
        background: 'none',
        border: 'none',
        padding: '0',
        fontFamily: 'inherit',
        cursor: 'pointer',
        textDecoration: 'underline',
        color: "#5874a3",
    },
    varInfoButton: {
        backgroundColor: '#eee',
        color: '#666',
        outlineWidth: 0, 
        outline: 'none', 
        boxShadow: 'none',
        borderRadius: '5px',
        border: '1px solid #ccc',
        padding: '5px 15px',
        marginBottom: '5px',
        marginLeft: '5px',
        marginRight: '10px',
        cursor: 'pointer',
        fontSize: '0.85rem',
    },
    variableList: {
        listStyleType: 'none',
        padding: 0,
        margin: '5px 10px',
        fontSize: '0.85rem',
    },
    variable_description: {
        marginLeft: '10px',
        marginRight: '10px',
        fontSize: '0.85rem',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        padding: '3px'
    },
    searchContainer: {
        marginBottom: '5px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '80vw'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    formContainer: {
        flex: '0 0 23%',
    },
    mapContainer: {
        flex: '0 0 74%',
        margin: '5px',
    },
    form: {
        fontFamily: 'Arial, sans-serif',
        width: '100%',
        marginBottom: '20px',
        fontSize: '0.85rem',
    },
    formDivider: {
        margin: '0 4px 0 4px',
        textAlign: 'center',
    },
    input: {
        borderRadius: '5px',
        border: '1px solid #ccc',
        padding: '10px',
        width: '100%',
        boxSizing: 'border-box',
        outline: 'none',
        fontSize: '0.85rem',
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        marginBottom: '5px',
        width: "100%",
    },
    label: {
        marginBottom: '5px',
        display: 'block',
        fontSize: '0.85rem',
    },
    fullWidth: {
        flex: '1 1 100%',
        flexGrow: 1,
        flexBasis: '100%'
    },
    large: {
        flex: 2,
        flexGrow: 1
    },
    medium: {
        flex: 1,
        flexGrow: 1
    },
    small:  {
        flex: 0.5
    },
    frozenInput: {
        backgroundColor: '#eee',
        color: '#666',
        outlineWidth: 0, 
        outline: 'none', 
        boxShadow: 'none',
        borderRadius: '5px',
        border: '1px solid #ccc',
        padding: '5px 10px',
        marginBottom: '5px',
        fontSize: '0.85rem',
    },
    unFrozenInput: {
        color: '#666',
        outlineWidth: 0, 
        outline: 'none', 
        boxShadow: 'none',
        borderRadius: '5px',
        border: '1px solid #ccc',
        padding: '5px 10px',
        marginBottom: '5px',
        fontSize: '0.85rem',
    },
    semiFrozenInput: {
        backgroundColor: '#f9f9f9',
        color: '#666',
        fontSize: '0.85rem',
    },
    displayOnly: {
        borderRadius: '5px',
        border: '1px solid #ccc',
        padding: '5px',
        marginBottom: '5px',
        width: '100%',
        color: '#333',
        fontSize: '0.85rem',
    },
    infoBox: {
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        margin: '5px',
        lineHeight: '1.5', 
        fontSize: '0.7rem',
        width: '100%',
        height: '61.5vh',
        overflowY: 'scroll',
    },
    infoBoxSmall: {
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        margin: '5px',
        lineHeight: '1.5', 
        fontSize: '0.7rem',
        width: '100%',
        height: '36.5vh',
        overflowY: 'scroll',
    },
    altMaps: {
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        margin: 'auto',
        lineHeight: '1.5', 
        fontSize: '0.7rem',
        width: '80vw',
        height: '46.5vh',
        overflowY: 'scroll',
    },
    altMapsSmall: {
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        margin: 'auto',
        lineHeight: '1.5', 
        fontSize: '0.7rem',
        width: '80vw',
        height: '11vh',
        overflowY: 'scroll',
    },
    error: {
        color: 'red',
        fontSize: '0.8rem',
        width: '100%'
    },
    warning: {
        color: '#b34800',
        fontSize: '0.8rem',
        width: '100%'
    },
    // Add media queries for mobile devices
    '@media (max-width: 768px)': {
        container: {
            padding: '10px',
        },
        contentContainer: {
            flexDirection: 'column',
        },
        formContainer: {
            flex: '1 1 auto',
            marginRight: '0',
            marginBottom: '20px',
            width: '100%',
            fontSize: '1rem',
        },
        mapContainer: {
            flex: '1 1 auto',
            width: '100%',
        },
        varInfoButton: {
            padding: '2px 10px',
            marginBottom: '2px',
            marginLeft: '5px',
            marginRight: '15px',
        },
        variableList: {
            margin: '5px 15px',
        },
        variable_description: {
            marginLeft: '15px'
        },
    }
};