import { SearchData, fetch_json, API_URL, } from './utils';
import { useEffect, useState } from 'react';

async function parseQuery(search_data: any, update: (new_data: any) => void) {
    let url = API_URL + 'parse_query?query=' + encodeURIComponent(search_data.raw_q);
    let data = await fetch_json(
        url,
        (err) => update({requested_indicators: null, error_message: 'Unable to parse query'})
    )

    let indicators;
    let error_message = null;

    if (data['var_q1']) {
        // multiple indicators
        indicators = [
            {
                var_q: data['var_q1'],
                vintage: data['vintage1'],
                show_as: data['show_as1']
            },
            {
                var_q: data['var_q2'],
                vintage: data['vintage2'],
                show_as: data['show_as2']
            }
        ]
    }
    else if (data['var_q']) {
        indicators = [
            {
                var_q: data['var_q'],
                vintage: data['vintage'],
                show_as: data['show_as']
            }
        ]
    }
    else {
        update({requested_indicators: null, error_message: 'Unable to parse query'});
        return;
    }
    let results:any = {
        requested_indicators: indicators,
        scope_name: data['scope_name'],
        scope: data['scope'],
        spatial_resolution: data['spatial_resolution'],
        location: data['location']
    }
    if (error_message) {
        results.warning_message = error_message;
    }
    update(results);
}

export type ParsedQueryProps = {
    data: SearchData,
    update: (new_data: any) => void
}

export function ParsedQuery(props: ParsedQueryProps) {
    let [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.data.raw_q && !props.data.requested_indicators && !loading && !props.data.error_message) {
            setLoading(true);
            parseQuery(props.data, props.update)
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
    }, [props.data]);

    if (props.data.raw_q && !props.data.requested_indicators) {
        return <div>Searching for: <span style={{fontStyle: 'italic'}}>{props.data.raw_q}</span></div>;
    }

    return <div style={{display: 'none'}}></div>;
}