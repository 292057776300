import { useEffect, useRef, useState, CSSProperties } from 'react';
import { Level } from '../types';
import { styles } from './styles';


export type InputType = 'system' | 'frozen' | 'semi_frozen' | 'normal';

export const API_URL = process.env.API_URL;

export type IndicatorQuery = {
    var_q: string;
    vintage: string;
    show_as: string;
}

export type AlternativeMap = {
    name: string;
    display_name: string;
    key: string;
    similarity_score: number;
    source: string;
}

export type IndicatorResult = {
    expression: string;
    name: string;
    data_product: string;
    vintage: string;
    uom: string;
    meta: ExpressionMetadata;
    alternative_maps: AlternativeMap[];
    confirmed: boolean;
    failed: boolean;
}

export type ExpressionMetadata = {
    vintage_avails: any,
    geo_avails: any,
    all_uoms: string[],
    variable_meta: any,
    map_names: any,
    shared_norms: string[],
}

export type SearchData = {
    raw_q: string;

    requested_indicators: IndicatorQuery[];
    
    scope_name: string;
    scope: string[];
    spatial_resolution: Level;
    location: [number, number];

    results: IndicatorResult[];

    error_message: string;
    warning_message: string;
};

type DropDownProps = {
    value: string;
    options: string[];
    input_type?: 'frozen' | 'semi_frozen' | 'unfrozen';
    hidden?: boolean;
    style?: React.CSSProperties;
    update: (val: any) => void;
}

type InputState = {
    value: string;
    has_focus: boolean; 
}

type DropDownState = {
    has_focus: boolean;
}

export function showAs(query: string) {
    if (percentChange(query)) {
        return 'percent_change';
    }
    else if (percentOfTotal(query)) {
        return 'percent_of_total';
    }
    else if (density(query)) {
        return 'per_square_mile';
    }
    return null;
}

export function percentChange(query: string) {
    let lower_q = query.toLowerCase();
    if ( lower_q.includes('change') || lower_q.includes('growth') || lower_q.includes('increase') || lower_q.includes('decrease') || lower_q.includes('percent change') || lower_q.includes('percent growth') || lower_q.includes('percent increase') || lower_q.includes('growth rate') ) {
        return true;
    }
    return false;
}

export function percentOfTotal(query: string) {
    let lower_q = query.toLowerCase();
    if ( lower_q.includes('percent') || lower_q.includes('rate') || lower_q.includes('proportion') || lower_q.includes('per capita') ) {
        return true;
    }
    return false;
}

export function density(query: string) {
    let lower_q = query.toLowerCase();
    if ( lower_q.includes('density') || lower_q.includes('per square mi')  || lower_q.includes('per sq. mi') || lower_q.includes('per sq mi')) {
        return true;
    }
    return false;
}

export function DropDownInput(props: DropDownProps) {
    let initial_state: DropDownState = {
        has_focus: false
    }
    const [state, setState] = useState<DropDownState>(initial_state);

    let style:CSSProperties = {...styles.input};
    if (props.input_type == 'frozen' && !state.has_focus) {
        style = {...style, ...styles.frozenInput};
    }
    else if (props.input_type == 'frozen' && state.has_focus) {
        style = {...style, ...styles.unFrozenInput};
    }
    else if (props.input_type == 'semi_frozen' && !state.has_focus) {
        style = {...style, ...styles.semiFrozenInput};
    }

    return (
        <div style={props.style || {}}>
            <select
                style={props.hidden ? { display: 'none' } : style}
                value={props.value}
                onChange={ev => props.update(ev.target.value)}
                onFocus={() => setState({ has_focus: true })}
                onBlur={() => setState({ has_focus: false })}
            >
                {props.options.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </select>
        </div>
    );
}

export function SearchInput(props) {
    let initial_state = {
        value: props.initialValue || '',
        has_focus: false
    }
    const [state, setState] = useState(initial_state);
    const value = state.value;
    let style = {...styles.input};
    let placeholder = props.placeholder || 'Search...';
    
    if (props.input_type === 'frozen' && !state.has_focus) {
        style = {...style, ...styles.frozenInput};
    } else if (props.input_type === 'frozen' && state.has_focus) {
        style = {...style, ...styles.unFrozenInput};
    } else if (props.input_type === 'semi_frozen' && !state.has_focus) {
        style = {...style, ...styles.semiFrozenInput};
    }

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <div style={props.style || {}}>
            <input
                ref={inputRef}
                style={props.hidden ? {display: 'none'} : style}
                value={value}
                onChange={ev => {
                    setState({value: ev.target.value, has_focus: state.has_focus});
                }}
                onKeyDown={ev => {
                    if (ev.key === 'Enter') {
                        if (value !== props.initialValue) {
                            props.updateValue(value);
                        }
                        inputRef.current.blur();
                    }
                }}
                onFocus={ev => {
                    setState({value: state.value, has_focus: true});
                }}
                onBlur={ev => {
                    setState({value: state.value, has_focus: false});
                }}
                readOnly={props.input_type === 'frozen' && !state.has_focus}
                inputMode="search"
                placeholder={placeholder}
            />
        </div>
    );
}

export async function fetch_json(url: string, on_error_resp: (error: any) => void = null) {
    let accept_header = 'application/json';
    let headers = new Headers();
    headers.append('Accept', accept_header);
    let data = await fetch(url, {headers: headers});
    if (data.status == 200) {
        return await data.json();
    }
    else {
        if (on_error_resp) {
            try {
                let data_obj = await data.json();
                on_error_resp(data_obj);
            }
            catch (e) {
                on_error_resp({error_message: "API request failed and no response data could be parsed."});
            }
        }
        throw Error('API request failed with status: ' + data.status);
    }
}

export function queryKey(query: string) {
    if (!query) {
        throw Error('Tried to call queryKey with null query - caller should check for null first');
    }
    return query.toLowerCase().replace(/[.,\s]+/g, '');
}

export function findUom(res_var, metadata) {
    let res_uom = null;
    let uoms = metadata['all_uoms'];
    if (uoms.length == 1) {
        res_uom = uoms[0];
    }
    else {
        throw new Error(`Response is missing UOM or has multiple: ${uoms}`);
    }

    if (res_var.includes('~LAND_AREA')) {
        return `${res_uom} per square mile`;
    }
    else if (res_var.includes('~')) {
        return `percent of ${res_uom}`;
    }
    return res_uom;
}

export function canNormalizeByLand(uom: string) {
    // TODO: Some UOMs need to be split into aggregate and per-unit, and we need to disallow per-unit ones here
    // E.g. 'hours worked' per sq mi could be meaningful? but not 'mean hours worked'
    let disallowed = [
        'dollars',
        'percentage',
        'other'
    ]
    return !disallowed.includes(uom);
}