import { SearchData } from './utils';
import { SelectedVariable } from './selected_variable';
import { NormalizeSelector } from './normalize_selector';
import { TimeSelector } from './time_selector';
import { useState, useEffect } from 'react';
import { fetch_json, API_URL, findUom } from './utils';
import { styles } from './styles';
import { Level } from '../types';

type ResultFinderProps = {
    result_index: number;
    data: SearchData;
    update: (new_data: any) => void;
};

export function ResultFinder(props: ResultFinderProps) {
    let i = props.result_index;
    let style = styles.infoBox;
    if (props.data.requested_indicators.length > 1) {
        style = styles.infoBoxSmall;
    }
    let [fetchingResult, setFetchingResult] = useState<boolean>(null);

    useEffect(() => {
        if (!props.data.requested_indicators) {
            return;
        }
        else if (!props.data.results[i] && !fetchingResult && !props.data.error_message){
            setFetchingResult(true);
            findVariable(props.result_index, props.data, props.update)
                .then(() => setFetchingResult(null))
                .catch(() => setFetchingResult(null));
        }
    }, [props.data])

    if (!props.data.requested_indicators) {
        return <div style={{display: 'none'}}></div>;
    }
    if (fetchingResult) {
        return <div style={style}>Searching - Please Wait!</div>;
    }
    else if (!props.data.results[i] || !props.data.results[i].expression || props.data.results[i].expression == '') {
        return <div style={{display: 'none'}}></div>;
    }

    return (
        <div style={style}>
            <SelectedVariable result_index={i} data={props.data}update={props.update} />
            <NormalizeSelector result_index={i} data={props.data} update={props.update} />
            <TimeSelector result_index={i} data={props.data} update={props.update} />
        </div>
    );
}

const DATA_PRODUCT_PREFERENCES = [
    'acs5',
    'acs1'
]

function resolveRelativeVintage(vintage: string, available: string[]) {
    let latest_available = available[available.length - 1];
    let latest_int = parseInt(latest_available);
    vintage = vintage.toUpperCase();
    if (vintage.startsWith('LATEST_')) {
        return [latest_available];
    }
    else if (vintage.startsWith('CHANGE_')) {
        let start_year = parseInt(vintage.replace(/\D/g, ''));
        let start = start_year.toString();
        if (available.includes(start)) {
            return [start, latest_available];
        }
        else {
            return null;
        }
    }
    else if (vintage.startsWith('PAST_')) {
        let n_years = parseInt(vintage.replace(/\D/g, ''));
        let start_vintage = latest_int - n_years;
        let start = start_vintage.toString();
        if (available.includes(start)) {
            return [start, latest_available];
        }
        else {
            return null;
        }
    }
    else if (vintage.includes('-')) {
        let [start, end] = vintage.split('-');
        if (available.includes(start) && available.includes(end)) {
            return [start, end];
        }
        else {
            return null;
        }
    }
    else if (available.includes(vintage)) {
        return [vintage];
    }
    else {
        return null;
    }
}

async function findVariable(result_index: number, search_data: SearchData, update: (new_data: any) => void) {
    let { spatial_resolution: requested_spatial_resolution } = search_data;
    let { var_q, vintage: requested_vintage } = search_data.requested_indicators[result_index];

    let resp = await fetch_json(
        API_URL + 'generate_map?var_q=' + encodeURIComponent(var_q) + '&spatial_resolution=' + encodeURIComponent(requested_spatial_resolution),
        (error_resp_data) => {
            let new_results = [];
            new_results[result_index] = {
                expression: null,
                name: null,
                data_product: null,
                vintage: null,
                uom: null,
                meta: null,
                alternative_maps: null,
                confirmed: false,
                failed: true

            }
            let data = {
                only_index: result_index,
                results: new_results,
                warning_message: 'No inicator could be automatically selected for this query - choose from options.',
            }

            update(data);
        }
    );

    let res_vintage = null;
    let res_data_product = null;

    let avails = resp.metadata['vintage_avails']
    let all_available_vintages = [];
    let warning = null;
    for (let data_product in avails) {
        all_available_vintages = all_available_vintages.concat(avails[data_product]);
    }
    let absolute_vintages = resolveRelativeVintage(requested_vintage, all_available_vintages);
    if (!absolute_vintages) {
        res_vintage = all_available_vintages[all_available_vintages.length - 1];
        warning = warning || '';
        warning += `The requested vintage ${requested_vintage} is not available. The latest available vintage (${res_vintage}) will be used instead. `
    }
    else {
        res_vintage = absolute_vintages[0];
        if (absolute_vintages.length > 1) {
            warning = warning || '';
            warning += `Ranges of vintages (${absolute_vintages[0]} - ${absolute_vintages[1]}) not yet supported - using ${absolute_vintages[0]} instead. `
        }
    }
    res_data_product = DATA_PRODUCT_PREFERENCES.find((dp) => avails[dp] && avails[dp].includes(res_vintage));

    // before setting res_var, decide if we should add a normalize
    // normally a concern of normalizeselector, but we need to normalize from the start sometimes, and res_var is set here
    let normalize_options = resp.metadata['shared_norms'];
    let expression = resp.expression;
    let show_as_percent = search_data.requested_indicators[result_index].show_as == 'percent_of_total';
    let show_as_density = search_data.requested_indicators[result_index].show_as == 'per_square_mile';

    let dollars = resp.metadata['all_uoms'][0] == 'dollars';

    if (normalize_options && normalize_options.length > 0 && show_as_percent) {
        // find the normalize option alphanumerically first
        let selected = normalize_options.sort()[0];
        expression = `${expression}~${selected}`; 
    }
    else if (show_as_density && !dollars) {
        expression = `${expression}~LAND_AREA`;
    }

    let res_uom = findUom(expression, resp.metadata);

    let new_results = [];
    new_results[result_index] = {
        expression: expression,
        name: resp.display_name,
        data_product: res_data_product,
        vintage: res_vintage,
        uom: res_uom,
        meta: resp.metadata,
        alternative_maps: null,
        confirmed: false,
        failed: false
    }
    let data = {
        only_index: result_index,
        results: new_results,
        warning_message: warning
    }

    update(data);
}