import { Level } from "../types";
import * as ZIP_PREFIXES from './zip_prefixes.json';
import * as ZIP_COUNTIES from './zip_counties.json';

export function findLevel(geo_id: string) {
    switch (geo_id.length) {
        case 1:
            return Level.Country;
        case 2: 
            return Level.State;
        case 5: 
            return Level.County;
        case 10:
            return Level.ZipCode; // ZCTA_XXXXX 
        case 11:
            return Level.Tract;
        case 12:
            return Level.BlockGroup;
    }
    if (geo_id.length > 12) {
        return Level.Block;
    }
    throw new Error('invalid geo_id: ' + geo_id);
}

export function defaultChild(level: Level) {
    switch (level) {
        case Level.Country:
            return Level.State;
        case Level.State:
            return Level.County;
        case Level.County:
            return Level.Tract;
        case Level.Tract:
            return Level.Block;
        case Level.BlockGroup:
            return Level.Block;
        case Level.ZipCode:
            return Level.Block;
        default:
            return null;
    }
}

export function parentLevel(level: Level) {
    switch (level) {
        case Level.State:
            return Level.Country;
        case Level.County:
            return Level.State;
        case Level.Tract:
            return Level.County;
        case Level.BlockGroup:
            return Level.County;
        case Level.Block:
            return Level.Tract;
        default:
            return null;
    }    
}

export function children(level: Level) {
    switch (level) {
        case Level.Country:
            return [Level.State, Level.County, Level.ZipCode]
        case Level.State:
            return [Level.County, Level.ZipCode];
        case Level.County:
            return [Level.Tract, Level.BlockGroup, Level.ZipCode, Level.Block];
        case Level.Tract:
            return [Level.Block];
        case Level.BlockGroup:
            return [Level.Block];
        case Level.ZipCode:
            return [Level.Block];
        default:
            return [level];
    }
}
// ancestors returns array of arrays. 
// For Cook County, returns [ [US], [IL] ]
// For a collection of tracts, it might return [ [US], [IL WI], [Cook County, Milwaukee County] ]
export function findAncestors(ids: string[]) {
    if (ids.length == 0) {
        return [];
    }
    let first_id = ids[0];
    let level = findLevel(first_id);
    if (level == Level.ZipCode) {
        let counties = [];
        ids.forEach(id => {
            let zip = id.split('_')[1];
            let parents = ZIP_COUNTIES[zip];
            counties = counties.concat(parents);
        });
        counties = Array.from(new Set(counties));
        let res = findAncestors(counties);
        res.push(counties);
        return res
    }
    else {
        let ancestors = [];
        while (!ids.includes('0')) {
            ids = uniqueParents(ids);
            ancestors.push(ids);
        }
        ancestors = ancestors.reverse();
        return ancestors;
    }
}
export function uniqueParents(ids: string[]) {
    let level = findLevel(ids[0]);
    let prefix_len;
    switch (level) {
        case Level.State :
            return ['0'];
        case Level.County: 
            prefix_len = 2;
            break;
        default:
            prefix_len = 5;
    }
    let prefixes = ids.map(id => id.slice(0, prefix_len));
    return Array.from(new Set(prefixes));
}

export function plural(level: Level) {
    if ([Level.Country, Level.County].includes(level)) {
        let pluralized = level.substring(0, level.length - 1) + 'ies';
        return titleize(pluralized);
    }
    else if (level == Level.ZipCode) {
        return 'Zip Codes';
    }
    else {
        return titleize(level + 's');
    }
}

function titleize(str: string) {
    return str[0].toUpperCase() + str.slice(1);
}

export function within(place_key: string, parent_key: string) {
    if (parent_key.length == 1) {
        return true;
    }
    let target_level = findLevel(place_key);
    let parent_level = findLevel(parent_key);

    if (parent_level == Level.ZipCode) {
        // this is not implemented but doesn't cause any issues (trust the KV for blocks-in-zcta)
        return true;
    }

    if (target_level !== Level.ZipCode) {
        return place_key.startsWith(parent_key);
    }
    else {
        if (parent_level == Level.County) {
            let prefix = place_key.substring(5,11);
            if (!ZIP_COUNTIES[prefix]) {
                return false;
            }
            return ZIP_COUNTIES[prefix].includes(parent_key);
        }
        else {
            let prefix = place_key.substring(5,8);
            return ZIP_PREFIXES[prefix] == parent_key;
        }
    }
}