import * as Leaflet from 'leaflet';
import { Map } from '../map';

export class Legend {
    map: Map;
    leaflet_legend: Leaflet.Control;

    constructor(map) {
        this.map = map;
        this.leaflet_legend = null;
    } 

    add() {
        this.leaflet_legend.addTo(this.map.leaflet_map);
    }

    remove() {
        if (this.leaflet_legend) {
            this.map.leaflet_map.removeControl(this.leaflet_legend);
            this.leaflet_legend = null;
        }
    }

    update() {
        this.remove();
        if (this.map.data_hidden) {
            return;
        }
        let style = this.map.scope.active_layer.map_style;
        let buckets = style.getBuckets();
        this.createLeafletLegend(buckets);
        this.add();
    }

    formatValue(value: number): string {
        // if percent, multiply by 100 and add percent sign 
        if (this.map.data_set.key.includes('~') && !this.map.data_set.key.includes('~LAND_AREA')) {
            return (value * 100).toFixed(2) + '%';
        }
        else {
            // add commas if needed 
            let formatted = value.toLocaleString(undefined, { maximumFractionDigits: 2 });
            return formatted;
        }
    }

    createLeafletLegend(buckets) {
        let legend = new Leaflet.Control({position: 'topleft'});
        this.leaflet_legend = legend;

        legend.onAdd = (map) => {
            let div = Leaflet.DomUtil.create('div', 'info legend');
            let label = Leaflet.DomUtil.create('h3','legend-label grey', div);
            label.innerText = this.map.data_set.uom;
            buckets.forEach( bucket => {
                let [min, max, color] = bucket;
                min = this.formatValue(min);
                max = this.formatValue(max);
                div.innerHTML += `<i style="background:${color}"></i> ${min} &ndash; ${max}<br>`;
            });
            return div;
        };
    }
}