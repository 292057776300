import { Map } from '../../map';
import { Level } from '../../types';
import { plural, children, findLevel } from '../../helpers/levels';
import { Hierarchy } from './hierarchy';
import * as Leaflet from 'leaflet';

export class ZoomOptions {
    map: Map;
    parent_div: HTMLElement;
    hierarchy: Hierarchy;

    constructor(map: Map, div: HTMLElement) {
        this.map = map;
        this.parent_div = div;
        this.update();
    }

    update() {
        let container = Leaflet.DomUtil.create('div', 'control-section', this.parent_div);
        this.hierarchy = new Hierarchy(this.map, container);
        if (this.map.data_hidden) {
            return;
        }

        let toggle_options = Leaflet.DomUtil.create('h4','', container);

        let current_spatial_resolution = this.map.scope.spatial_resolution;
        let parent_spatial_resolution = findLevel(this.map.scope.place_ids[0]);
        children(parent_spatial_resolution).forEach( (peer, i) => {
            let ele;
            if (peer == current_spatial_resolution) {
                ele = Leaflet.DomUtil.create('span', '', toggle_options);
            }
            else {
                ele = Leaflet.DomUtil.create('button', 'linkbutton', toggle_options);
                ele.onclick = (ev) => this.handleToggleLevelClick(ev, peer);
            }
            ele.innerText = plural(peer);
        })
    }

    async handleToggleLevelClick(ev: Leaflet.LeafletEvent, target: Level) {
        Leaflet.DomEvent.stopPropagation(ev);
        if (target == Level.Block && !['P20', 'H20', 'O20', 'V20'].includes(this.map.data_set.key)) {
            let prev_key = this.map.data_set.key;
            let prev_name = this.map.data_set.name;
            let available = ['P20', 'H20', 'O20', 'V20'];
            // if any of avail is substr of prev_key, do nothing
            if (!available.some( (avail) => prev_key.includes(avail))) {
                let result = confirm(`This indicator (${prev_name}) is unavailable for blocks - switch to Total Population (2020 Decennial Census)?`)
                if (!result) {
                    return;
                }
                await this.map.forceTotalPopulation();
            }
        }
        this.map.changeSpatialResolution(target);
    }
}