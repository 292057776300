import * as Leaflet from 'leaflet';
import { Map } from '../map';

export class Layer {
    map: Map;
    leaflet_layer: Leaflet.Layer;

    constructor() {}

    add() {
        if (this.map && this.leaflet_layer) {
            this.leaflet_layer.addTo(this.map.leaflet_map);
        }
    }

    remove() {
        if (this.map && this.leaflet_layer) {
            this.map.leaflet_map.removeLayer(this.leaflet_layer);
        }
    }
}