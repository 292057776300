import { handleUrl } from './helpers/handle_url';
import { createRoot } from "react-dom/client";
import { SearchMachine } from "./search/search_machine"

// creates a react app, parses the url, and renders a SearchMachine
export async function init( ) {
    let root = document.getElementById('react_root');
    let root_component = createRoot(root);
    let url_info = await handleUrl();
    root_component.render(<SearchMachine url_info={url_info}/>);
}
