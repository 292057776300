import { constants } from './helpers/constants';
import { LeafletMap } from './helpers/leaflet_map';
import { ControlPanel } from './map_elements/control_panel/control_panel';
import { Legend } from './map_elements/legend';
import { MapScope } from './map_data/map_scope';
import { NeighborsLayer } from './map_data/neighbors_layer';
import * as Leaflet from 'leaflet';
import { PlaceRepository } from './map_data/place_repository';
import { DataSet, Level } from './types';
import { AddressMarker } from './map_elements/address_marker';

export class Map {
    leaflet_map: Leaflet.Map;
    control_panel: ControlPanel;
    legend: Legend;
    neighbors: NeighborsLayer;
    scope: MapScope;
    places: PlaceRepository;
    data_set: DataSet; 
    data_hidden: boolean = false;
    address_marker: AddressMarker;
    sync_to: Map = null;
    forceTotalPopulation: () => void;

    constructor(map_div: string, url_info: any, forceTotalPopulation: () => void) {
        (window as any).map = this;
        this.forceTotalPopulation = forceTotalPopulation;
        this.leaflet_map = LeafletMap.buildMap(map_div, this);
        this.control_panel = new ControlPanel(this);
        this.address_marker = new AddressMarker(this);
        this.data_set = url_info['data_set'];
        this.legend = new Legend(this);
        this.scope = new MapScope(this);
        this.neighbors = new NeighborsLayer(this);
        this.places = new PlaceRepository(this);

        let initial_scope = url_info['scope'] || ['0'];
        let initial_spatial_resolution = url_info['spatial_resolution'] || 'state';
        let should_zoom = initial_scope[0] !== '0';
        this.scope.update(initial_scope, initial_spatial_resolution).then(() => {
            let map_update = this.update(should_zoom);
            let neighbor_update = this.neighbors.update(initial_scope)
            if (url_info['location']) {
                Promise.all([map_update, neighbor_update]).then(() => {
                    this.address_marker.update(url_info['location']);
                });
            }
        });
    }

    syncDataUpdates(other: Map) {
        this.sync_to = other;
    }

    async update(zoom: boolean = true, from_sync: boolean = false) {
        if (this.sync_to && !from_sync) {
            // apply geographic updates to the other map
            this.sync_to.scope.update(this.scope.place_ids, this.scope.spatial_resolution).then(() => {
                this.sync_to.neighbors.update(this.scope.place_ids);
                this.sync_to.update(false, true);
            })
        }
        this.leaflet_map.closePopup();
        await this.control_panel.update();

        let layer = this.scope.active_layer;

        if (zoom) {
            // If we're force-zooming, scope should always be length 1
            if (this.scope.place_ids.length===1 && this.scope.place_ids[0] in constants.CUSTOM_BOUNDS) {
                let bounds = constants.CUSTOM_BOUNDS[this.scope.place_ids[0]];
                this.leaflet_map.fitBounds(bounds);
            }
            else {
                this.leaflet_map.fitBounds(layer.leaflet_layer.getBounds());
            }
        }
    }

    async zoomTo(place_ids: string[]) {
        let success;
        try {
            success = await this.scope.update(place_ids);
        }
        catch (err) {
            console.error('err caused zoom failure:')
            console.error(err);
            return false;
        }

        this.neighbors.update(place_ids);
        this.update(true);
        return true;
    }

    async zoomOut() {
        if (!this.scope.canZoomOut()) {
            return;
        }

        let success = await this.scope.zoomOut();
        if (success) {
            this.neighbors.update(this.scope.place_ids);
            this.update(true);
        }
    }

    async changeSpatialResolution(target: Level) {
        let success = await this.scope.update(this.scope.place_ids, target);
        if (success) {
            this.update(false);
        }
    }

    toggleBase() {
        if (this.data_hidden) {
            this.unShowBase();
        }
        else {
            this.showBase();
        }
    }

    async showBase() {
        this.leaflet_map.getPane('tiles').style.zIndex = '5';
        this.data_hidden = true;
        await this.control_panel.update();
    }

    async unShowBase() {
        this.leaflet_map.getPane('tiles').style.zIndex = '1';
        this.data_hidden = false;
        await this.control_panel.update();
        await this.update(false);
    }

    scopes_equal(scope1, scope2) {
        if ((!scope1 || !scope2) && scope1 != scope2) {
            return false;
        }
        if (scope1.length != scope2.length) {
            return false;
        }
        let s2 = new Set(scope2);
        for (var i = 0; i < scope1.length; i++) {
            let item = scope1[i];
            if (!s2.has(item)) {
                return false;
            }
        }
        return true;
    }

    async hideMap() {
        this.showBase()
    }

    async showMap() {
        this.unShowBase();
    }

    dataSetsDiffer(ds1, ds2) {
        let keys = Object.keys(ds1);
        for (var i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (ds1[key] != ds2[key]) {
                return true;
            }
        }
    }
}